import {
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Button,
	Center,
	Image,
	useDisclosure,
	useMediaQuery,
	Link,
} from '@chakra-ui/react';
import React from 'react';
import { faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
	removeAccessToken,
	removeAuthMsg,
	removeUser,
} from '../../auth/redux/userRedux';
import { logout } from '../../auth/redux/authAPI';
import './activelink.css';
import MobileDrawer from './MobileDrawer';
import { Link as domLink } from 'react-router-dom';

export default function Header() {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isNotMobile] = useMediaQuery('(min-width: 500px)');

	const logoutFunction = () => {
		logout()
			.then((res) => {
				dispatch(removeAccessToken());
				dispatch(removeUser());
				dispatch(removeAuthMsg());
			})
			.catch((err) => {
				console.log(err);
			});
	};
	return (
		<>
			<MobileDrawer isOpen={isOpen} onClose={onClose} user={user} />
			<Flex
				justifyContent={{ base: 'space-between', lg: 'flex-end' }}
				alignItems='center'
				height='70px'
				minHeight='70px'
				px={{ base: '2', md: '6' }}
				position='sticky'
				top='0px'
				bg='white'
				zIndex='2'
				borderBottom='1px solid'
				borderColor='gray.100'
			>
				<Center
					height='70px'
					bg='white'
					display={{ base: 'flex', lg: 'none' }}
					borderBottom='1px solid'
					borderColor='gray.100'
				>
					<Button
						fontSize='30px'
						onClick={onOpen}
						colorScheme='white'
						color='gray.700'
						mr='2'
					>
						<FontAwesomeIcon icon={faBars} />
					</Button>
					<Link as={domLink} to='/'>
						<Image
							width={{ base: '140px', sm: '160px' }}
							src={`${process.env.PUBLIC_URL}/media/logo.png`}
							alt='kmmias'
						/>
					</Link>
				</Center>
				{user && (
					<Menu>
						<MenuButton
							as={Button}
							rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
							colorScheme='teal'
						>
							{isNotMobile ? user.full_name : user.full_name[0]}
						</MenuButton>
						<MenuList>
							<MenuItem
								as={domLink}
								to='/user/profile'
								_hover={{ backgroundColor: 'gray.200' }}
							>
								My Profile
							</MenuItem>
							<MenuItem
								onClick={() => logoutFunction()}
								_hover={{ backgroundColor: 'gray.200' }}
							>
								Logout
							</MenuItem>
						</MenuList>
					</Menu>
				)}
			</Flex>
		</>
	);
}
