import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { setupAxios } from './redux/setupAxios';
import axios from 'axios';
import {
	removeAccessToken,
	setAccessToken,
} from './pages/auth/redux/userRedux';
import { removeUser } from './pages/auth/redux/userRedux';

setupAxios(axios, store, setAccessToken, removeAccessToken, removeUser);
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ChakraProvider>
				<App />
			</ChakraProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
