import axios from 'axios';

export const login = (data) => {
	return axios.post('/auth/login', data, { withCredentials: true });
};

export const register = (data) => {
	return axios.post('/auth/register', data);
};

export const refreshToken = () => {
	return axios.get('/auth/refresh-token', { withCredentials: true });
};

export const getCurrentUser = () => {
	return axios.get('/auth/current-user');
};

export const logout = () => {
	return axios.get('/auth/logout', { withCredentials: true });
};

export const verifyEmail = (data) => {
	return axios.post('/auth/verify-email', data);
};

export const forgotPassword = (data) => {
	return axios.post('/auth/forgot-password', data);
};

export const resetPassword = (data) => {
	return axios.post('/auth/reset-password', data);
};

export const changePassword = (data) => {
	return axios.post('/auth/change-password', data);
};
