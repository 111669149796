import React, { useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import {
	Box,
	Heading,
	SimpleGrid,
	GridItem,
	Text,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Button,
	Select,
	Flex,
	Alert,
	AlertIcon,
	AlertDescription,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import FileUpload from '../../../utils/components/FileUpload';
import _ from 'lodash';
import { addFiles, getFile, patchFiles } from '../redux/AdmissionAPI';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { steps } from '../../../utils/constants';
import { Link, useHistory } from 'react-router-dom';
import { updateUser } from '../../auth/redux/userRedux';
import { isObjectKeysEmpty } from '../../../utils/isObjectKeysEmpty';
import { FileRemove } from '../../../utils/components/FileRemove';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Uploads() {
	const { user } = useSelector((state) => state.auth);

	const history = useHistory();
	const dispatch = useDispatch();

	const [initialValues, setinitialValues] = useState({
		photo: '',
		id_card_type: '',
		id_card: '',
		marksheet: '',
		signature: '',
	});
	const [isDownloads, setisDownloads] = useState(false);

	const [validationSchema, setvalidationSchema] = useState();

	const downloadFile = (fileName) => {
		formik.setStatus(null);
		return getFile({ fileName, student_id: user.student_id })
			.then((res) => {
				const data = res.data; // or res.blob() if using blob responses

				const url = window.URL.createObjectURL(
					new Blob([data], {
						type: res.headers['content-type'],
					})
				);

				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					`${user.student_id}-${user.applying_for}-${fileName}`
				);
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			})
			.catch((err) => {
				formik.setStatus(err.response.data.message);
			});
	};

	const submitFunction = (values, setStatus, setErrors) => {
		if (isObjectKeysEmpty(values)) {
			return history.push(`/admission-form/${user.applying_for}/confirmation`);
		}

		const formData = new FormData();
		Object.keys(values).forEach((key) => {
			formData.append(key, values[key]);
		});

		if (
			user.current_form > 4 ||
			(user.applying_for === 'pgdm' && user.current_form > 3)
		) {
			return patchFiles(formData)
				.then((res) => {
					history.push(`/admission-form/${user.applying_for}/confirmation`);
				})
				.catch((err) => {
					if (err?.response?.data?.errors) {
						return setErrors(err.response.data.errors);
					}
					return setStatus(err.response.data.message);
				});
		}

		return addFiles(formData)
			.then((res) => {
				dispatch(updateUser({ current_form: res.data.current_form }));
				history.push(
					`/admission-form/${user.applying_for}/${
						steps[user.applying_for][res.data.current_form]
					}`
				);
			})
			.catch((err) => {
				if (err?.response?.data?.errors) {
					return setErrors(err.response.data.errors);
				}
				return setStatus(err.response.data.message);
			});
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
		onSubmit: (values, { setStatus, setErrors }) => {
			return submitFunction(values, setStatus, setErrors);
		},
	});

	const handleChange = (files, field) => {
		if (!_.isEmpty(files)) {
			return formik.setFieldValue(field, files[0]);
		}
		return formik.setErrors({
			[field]:
				'Invalid file format, please only upload one of the allowed formats',
		});
	};

	useEffect(() => {
		if (user.applying_for === 'pgdm' && user.current_form < 3) {
			history.push(
				`/admission-form/${user.applying_for}/${
					steps[user.applying_for][user.current_form]
				}`
			);
		}
		if (user.applying_for === 'pgdme' && user.current_form < 4) {
			history.push(
				`/admission-form/${user.applying_for}/${
					steps[user.applying_for][user.current_form]
				}`
			);
		}
	}, [history, user]);

	useEffect(() => {
		let isSubmitted = false;
		if (
			(user.applying_for === 'pgdm' && user.current_form > 3) ||
			user.current_form > 4
		) {
			isSubmitted = true;
			setisDownloads(true);
		}
		const defaultSchema = {
			photo: Yup.mixed().test(
				'file-size',
				'Photo cannot be greater than 1 MB',
				(val) => {
					if (!val) {
						return isSubmitted;
					}
					return val.size <= 1 * 1024 * 1024;
				}
			),
			id_card_type: Yup.string()
				.oneOf(['pan', 'aadhaar', 'passport', 'voter', ''])
				.label('Select type of ID')
				.when('id_card', {
					is: (id_card) => {
						if (isSubmitted && id_card) {
							return true;
						}
						if (!isSubmitted) {
							return true;
						}
						return false;
					},
					then: Yup.string().required(),
				}),
			id_card: Yup.mixed().when('id_card_type', {
				is: (id_card_type) => {
					console.log(id_card_type);
					if (isSubmitted && id_card_type) {
						return true;
					}
					if (!isSubmitted) {
						return true;
					}
					return false;
				},
				then: Yup.mixed().test(
					'file-size',
					'ID card is required and cannot be greater than 1 MB',
					(val) => {
						if (!val) {
							return false;
						}
						return val.size <= 1 * 1024 * 1024;
					}
				),
			}),
			marksheet: Yup.mixed().test(
				'file-size',
				'Marksheet cannot be greater than 2 MB',
				(val) => {
					if (!val) {
						return isSubmitted;
					}
					return val.size <= 2 * 1024 * 1024;
				}
			),
			signature: Yup.mixed().test(
				'file-size',
				'Signature cannot be greater than 512 KB',
				(val) => {
					if (!val) {
						return isSubmitted;
					}
					return val.size <= 1 * 1024 * 1024;
				}
			),
		};

		const workExperienceSchema = {
			work_experience_certificate: Yup.mixed().test(
				'file-size',
				'Work Experience proof cannot be greater than 2 MB',
				(val) => {
					if (!val) {
						return isSubmitted;
					}
					return val.size <= 2 * 1024 * 1024;
				}
			),
		};

		if (user.applying_for === 'pgdm') {
			setvalidationSchema(
				Yup.object().shape({ ...defaultSchema }, [['id_card', 'id_card_type']])
			);
		} else {
			setvalidationSchema(
				Yup.object().shape({ ...defaultSchema, ...workExperienceSchema }, [
					['id_card', 'id_card_type'],
				])
			);
			setinitialValues((i) => ({ ...i, work_experience_certificate: '' }));
		}
	}, [user.applying_for, user.current_form]);

	return (
		<Box px={{ base: '2', md: '10' }}>
			<form onSubmit={formik.handleSubmit} encType='multipart/form-data'>
				<Heading as='h4' size='xl' mb='10'>
					Step {user.applying_for === 'pgdm' ? 4 : 5}: Document Uploads
				</Heading>
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} spacing={10} mb='10'>
					<GridItem colSpan={{ base: 1, md: 2, xl: 2 }}>
						<Text
							fontSize='sm'
							color='gray.500'
							textDecoration='underline'
							mb='2'
						>
							Document Uploads
						</Text>
						{formik.status && (
							<Alert status='error' mb='5'>
								<AlertIcon />
								<AlertDescription>{formik.status}</AlertDescription>
							</Alert>
						)}
					</GridItem>
					<FormControl isInvalid={formik.errors.photo}>
						<Flex alignItems='center' justifyContent='space-between' mb='2'>
							<FormLabel>Photograph:</FormLabel>
							{isDownloads && (
								<Button
									type='button'
									size='sm'
									colorScheme='gray'
									variant='ghost'
									onClick={() => downloadFile('photo')}
								>
									<FontAwesomeIcon icon={faFileDownload} />
								</Button>
							)}
						</Flex>
						<FileUpload
							handleChange={handleChange}
							isMultiple={false}
							name='photo'
							accept='Image/png, Image/jpg, Image/jpeg, Application/pdf'
						/>
						<FileRemove formik={formik} field='photo' />
						<FormErrorMessage>{formik.errors.photo}</FormErrorMessage>
					</FormControl>
					<Box>
						<FormControl mb='2' isInvalid={formik.errors.id_card_type}>
							<FormLabel>Id card:</FormLabel>
							<Select
								placeholder='Select an option'
								value={formik.values.id_card_type}
								onChange={(e) =>
									formik.setFieldValue('id_card_type', e.target.value)
								}
							>
								<option value='pan'>Pan card</option>
								<option value='aadhaar'>Aadhaar card</option>
								<option value='passport'>Passport</option>
								<option value='voter'>Voter ID</option>
							</Select>
							<FormErrorMessage>{formik.errors.id_card_type}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={formik.errors.id_card}>
							<Flex alignItems='center' justifyContent='space-between' mb='2'>
								<FormLabel>Photo Id:</FormLabel>
								{isDownloads && (
									<Button
										type='button'
										size='sm'
										colorScheme='gray'
										variant='ghost'
										onClick={() => downloadFile('id_card')}
									>
										<FontAwesomeIcon icon={faFileDownload} />
									</Button>
								)}
							</Flex>
							<FileUpload
								handleChange={handleChange}
								isMultiple={false}
								name='id_card'
								accept='Image/png, Image/jpg, Image/jpeg, Application/pdf'
							/>
							<FileRemove formik={formik} field='id_card' />
							<FormErrorMessage>{formik.errors.id_card}</FormErrorMessage>
						</FormControl>
					</Box>
					<FormControl isInvalid={formik.errors.marksheet}>
						<Flex alignItems='center' justifyContent='space-between' mb='2'>
							<FormLabel>Marksheet:</FormLabel>
							{isDownloads && (
								<Button
									type='button'
									size='sm'
									colorScheme='gray'
									variant='ghost'
									onClick={() => downloadFile('marksheet')}
								>
									<FontAwesomeIcon icon={faFileDownload} />
								</Button>
							)}
						</Flex>
						<FileUpload
							handleChange={handleChange}
							isMultiple={false}
							name='marksheet'
							accept='Image/png, Image/jpg, Image/jpeg, Application/pdf'
						/>
						<FileRemove formik={formik} field='marksheet' />
						<FormErrorMessage>{formik.errors.marksheet}</FormErrorMessage>
					</FormControl>
					{user && user.applying_for === 'pgdme' && (
						<FormControl isInvalid={formik.errors.work_experience_certificate}>
							<Flex alignItems='center' justifyContent='space-between' mb='2'>
								<FormLabel>Work Experience Certificate:</FormLabel>
								{isDownloads && (
									<Button
										type='button'
										size='sm'
										colorScheme='gray'
										variant='ghost'
										onClick={() => downloadFile('work_experience_certificate')}
									>
										<FontAwesomeIcon icon={faFileDownload} />
									</Button>
								)}
							</Flex>
							<FileUpload
								handleChange={handleChange}
								isMultiple={false}
								name='work_experience_certificate'
								accept='Image/png, Image/jpg, Image/jpeg, Application/pdf'
							/>
							<FileRemove formik={formik} field='work_experience_certificate' />
							<FormErrorMessage>
								{formik.errors.work_experience_certificate}
							</FormErrorMessage>
						</FormControl>
					)}
					<FormControl isInvalid={formik.errors.signature}>
						<Flex alignItems='center' justifyContent='space-between' mb='2'>
							<FormLabel>Signature:</FormLabel>
							{isDownloads && (
								<Button
									type='button'
									size='sm'
									colorScheme='gray'
									variant='ghost'
									onClick={() => downloadFile('signature')}
								>
									<FontAwesomeIcon icon={faFileDownload} />
								</Button>
							)}
						</Flex>
						<FileUpload
							handleChange={handleChange}
							isMultiple={false}
							name='signature'
							accept='Image/png, Image/jpg, Image/jpeg, Application/pdf'
						/>
						<FileRemove formik={formik} field='signature' />
						<FormErrorMessage>{formik.errors.signature}</FormErrorMessage>
					</FormControl>
				</SimpleGrid>
				<Flex justifyContent='space-between' alignItems='center'>
					<Button
						as={Link}
						colorScheme='gray'
						leftIcon={<ArrowBackIcon />}
						to={`/admission-form/${user.applying_for}/${
							user.applying_for === 'pgdm' ? 'academic' : 'work-experience'
						}-details`}
					>
						Previous Step
					</Button>
					<Button
						type='submit'
						colorScheme='green'
						rightIcon={<ArrowForwardIcon />}
						isLoading={formik.isSubmitting}
						spinnerPlacement='end'
					>
						Save and proceed
					</Button>
				</Flex>
				{/* <pre>{JSON.stringify(formik.values, null, 2)}</pre>
				<br />
				<pre>{JSON.stringify(formik.errors, null, 2)}</pre> */}
			</form>
		</Box>
	);
}
