import {
	Box,
	Heading,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import React from 'react';

export const WorkExperienceTable = ({ we }) => {
	if (!we || we.length === 0) {
		return (
			<Box>
				<Heading size='md' mb='3'>
					3. Work Experience Details
				</Heading>
				<Text>Student has not completed this part of the form yet</Text>
			</Box>
		);
	}
	return (
		<Box overflow='auto' mb='6'>
			<Heading size='md' mb='6'>
				3. Work Experience Details
			</Heading>

			<Table>
				<Thead>
					<Tr>
						<Th>Company</Th>
						<Th>From</Th>
						<Th>To</Th>
						<Th>Designation</Th>
						<Th>Responsibilities</Th>
					</Tr>
				</Thead>
				<Tbody>
					{we.map((we) => {
						return (
							<Tr key={we.work_experience_id}>
								<Td>{we.company_name}</Td>
								<Td>{we.from_date}</Td>
								<Td>{we.to_date}</Td>
								<Td>{we.designation}</Td>
								<Td>{we.responsibilities}</Td>
							</Tr>
						);
					})}
				</Tbody>
			</Table>
		</Box>
	);
};
