import { CloseIcon } from '@chakra-ui/icons';
import { Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';

export const FileRemove = ({ formik, field }) => {
	if (!formik.values[field]) {
		return null;
	}

	return (
		<Flex justifyContent='space-between' alignItems='center' mt='2'>
			<Text>{formik.values[field].path}</Text>
			<Button
				colorScheme='red'
				variant='ghost'
				type='button'
				size='xs'
				onClick={() => formik.setFieldValue(field, '')}
			>
				<CloseIcon />
			</Button>
		</Flex>
	);
};
