import {
	Alert,
	AlertDescription,
	AlertIcon,
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	Heading,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DynamicModal } from '../../../utils/components/DynamicModal';
import { confirmAdmission } from '../redux/AdmissionAPI';
import { updateUser } from '../../auth/redux/userRedux';

export default function Confirmation() {
	const { user } = useSelector((state) => state.auth);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const dispatch = useDispatch();

	const content = {
		head: 'Confirm submission',
		body: 'Once submitted you wont be able to make any changes to the form. Kindly confirm submission.',
	};

	const initialValues = {
		terms: false,
	};

	const validationSchema = Yup.object().shape({
		terms: Yup.bool().oneOf(
			[true],
			'You must accept the terms and conditions to continue'
		),
	});

	const submitFunction = (values) => {
		confirmAdmission(values)
			.then((res) => {
				dispatch(
					updateUser({
						is_form_submitted: 1,
						current_form: res.data.current_form,
					})
				);
				// history.push(`/admission-form/form-submitted`)
			})
			.catch((err) => {
				formik.setStatus(
					err.response.data.message ||
						'An error occurred while submitting, please try again later'
				);
				formik.setSubmitting(false);
			});
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			return onOpen();
		},
	});

	const customOnClose = () => {
		formik.setSubmitting(false);
		onClose();
	};
	return (
		<>
			<DynamicModal
				isOpen={isOpen}
				onClose={customOnClose}
				content={content}
				onConfirm={submitFunction}
				params={formik.values}
			/>
			<Box px={{ base: '2', md: '10' }} pb='32'>
				<Heading as='h4' size='xl' mb='10'>
					Step {user.applying_for === 'pgdm' ? 5 : 6}: Confirm Details
				</Heading>
				{formik.status && (
					<Alert status='error' mb='5'>
						<AlertIcon />
						<AlertDescription>{formik.status}</AlertDescription>
					</Alert>
				)}
				<Text mb='5'>
					Please click on any of the above steps to change/verify your details
					before you proceed
				</Text>
				<Text color='green.700' fontWeight='medium'>
					Note:
				</Text>
				<Text mb='5'>
					The admission fee for the form is being waived for the first year of
					admissions.
				</Text>
				<form onSubmit={formik.handleSubmit}>
					<Flex alignItems='top'>
						<FormControl
							id='graduation.university'
							isInvalid={formik.errors.terms}
							w='auto'
							mr='2'
							mt='1'
							isRequired
						>
							<Checkbox
								isInvalid={formik.errors.terms}
								isChecked={formik.values.terms}
								size='lg'
								onChange={() =>
									formik.setFieldValue('terms', !formik.values.terms)
								}
							></Checkbox>
						</FormControl>
						<Box mb='5'>
							<Text flexGrow='1'>
								I hereby declare that the information provided by me in the
								application form and the supporting documents is correct. If the
								furnished information is found misleading or incorrect, I will
								be responsible for any action taken that the institute may deem
								ﬁt. I also declare that I will abide with the rules and
								regulation of Bharatiya Vidya Bhavan’s K M Munshi Institute of
								Advanced Studies.
							</Text>
							<Text fontWeight='medium'>Please note:</Text>
							<Text>
								All disputes are in the jurisdiction of Mumbai/Navi Mumbai
							</Text>
							<Text color='red'>{formik.errors.terms}</Text>
						</Box>
					</Flex>
					<Box textAlign='right'>
						<Button
							type='submit'
							colorScheme='green'
							rightIcon={<ArrowForwardIcon />}
							isLoading={formik.isSubmitting}
							spinnerPlacement='end'
						>
							Save and proceed
						</Button>
					</Box>
				</form>
			</Box>
		</>
	);
}
