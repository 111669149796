import {
	Box,
	Heading,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import React from 'react';
import { DisplayField } from './DisplayField';

export const AddressPersonalTable = ({ pers, addr }) => {
	if (!pers) {
		return (
			<Box>
				<Heading size='md' mb='3'>
					1. Personal Details
				</Heading>
				<Text>Student has not completed this part of the form yet</Text>
			</Box>
		);
	}
	return (
		<Box overflow='auto' mb='6'>
			<Heading size='md' mb='6'>
				1. Personal Details
			</Heading>
			<Table variant='simple'>
				<Thead>
					<Tr>
						<Th>Details</Th>
						<Th>Email</Th>
						<Th>Phone</Th>
						{addr && <Th>Address</Th>}
					</Tr>
				</Thead>
				<Tbody>
					<Tr>
						<Td>
							<Text mb='1'>
								{`${pers.first_name}${
									pers.middle_name ? ` ${pers.middle_name} ` : ' '
								}${pers.last_name}`}
							</Text>
							<DisplayField label='Date of birth' value={pers.date_of_birth} />
							<DisplayField label='Gender' value={pers.gender} />
							<DisplayField label='Blood group' value={pers.blood_group} />
							<DisplayField label='Nationality' value={pers.nationality} />
						</Td>
						<Td>{pers.email}</Td>
						<Td>1231231231</Td>
						{addr && (
							<Td>
								<Text>{addr.address_line_1},</Text>
								{addr.address_line_2 && <Text>{addr.address_line_2},</Text>}
								<Text>
									{addr.district}, {addr.city},
								</Text>
								<Text>
									{addr.state}, {addr.pincode}
								</Text>
							</Td>
						)}
					</Tr>
					<Tr>
						<Td>
							<Text fontSize='xs' color='gray.500'>
								Father:
							</Text>
							<Text>{pers.father_name}</Text>
						</Td>
						<Td>{pers.father_email}</Td>
						<Td>1231231231</Td>
					</Tr>
					<Tr>
						<Td>
							<Text fontSize='xs' color='gray.500'>
								Mother:
							</Text>
							<Text>{pers.mother_name}</Text>
						</Td>
						<Td>{pers.mother_email}</Td>
						<Td>1231231231</Td>
					</Tr>
				</Tbody>
			</Table>

			{/* <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={2} mb='10'>
				<GridItem colSpan={{ base: 1, md: 2, xl: 3 }}>
					<Text
						fontSize='sm'
						color='gray.500'
						textDecoration='underline'
						mb='2'
					>
						Student's information
					</Text>
				</GridItem>
				<DisplayField label='First Name' value={pers.first_name} />
				<DisplayField label='Middle Name' value={pers.middle_name} />
				<DisplayField label='Last Name' value={pers.last_name} />
				<DisplayField label='Email' value={pers.email} />
				<DisplayField label='Phone' value={pers.phone} />
				<DisplayField label='Date of birth' value={pers.date_of_birth} />
				<DisplayField label='Gender' value={pers.gender} />
				<DisplayField label='Blood group' value={pers.blood_group} />
				<DisplayField label='Nationality' value={pers.nationality} />
			</SimpleGrid>

			<SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={2} mb='10'>
				<GridItem colSpan={{ base: 1, md: 2, xl: 3 }}>
					<Text
						fontSize='sm'
						color='gray.500'
						textDecoration='underline'
						mb='2'
					>
						Father's information
					</Text>
				</GridItem>
				<DisplayField label='Name' value={pers.father_name} />
				<DisplayField label='Email' value={pers.father_email} />
				<DisplayField label='Phone' value={pers.father_phone} />
			</SimpleGrid>

			<SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={2} mb='10'>
				<GridItem colSpan={{ base: 1, md: 2, xl: 3 }}>
					<Text
						fontSize='sm'
						color='gray.500'
						textDecoration='underline'
						mb='2'
					>
						Mother's information
					</Text>
				</GridItem>
				<DisplayField label='Name' value={pers.mother_name} />
				<DisplayField label='Email' value={pers.mother_email} />
				<DisplayField label='Phone' value={pers.mother_phone} />
			</SimpleGrid> */}
		</Box>
	);
};
