import React, { useEffect, useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import {
	Box,
	Heading,
	SimpleGrid,
	GridItem,
	Text,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	Button,
	Select,
	Alert,
	AlertIcon,
	AlertDescription,
	Flex,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	addAcademicDetails,
	getAcademicDetails,
	putAcademicDetails,
} from '../redux/AdmissionAPI';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../auth/redux/userRedux';
import { steps } from '../../../utils/constants';
import _ from 'lodash';

export default function AcademicDetails() {
	const history = useHistory();
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);

	const [initialValues, setinitialValues] = useState({
		ssc: {
			school: '',
			board: '',
			year_of_passing: '',
			score: '',
		},
		hsc: {
			school: '',
			board: '',
			main_subjects: '',
			year_of_passing: '',
			score: '',
		},
		graduation: {
			institute: '',
			degree: '',
			specialization: '',
			year_of_passing: '',
			university: '',
		},
	});

	const validationSchema = Yup.object().shape({
		ssc: Yup.object().shape({
			school: Yup.string().required().label('School').max(255),
			board: Yup.string().required().label('Board').max(255),
			year_of_passing: Yup.string().required().label('Year of passing').max(4),
			score: Yup.string().required().label('Score/Grade').max(10),
		}),
		hsc: Yup.object().shape({
			school: Yup.string().required().label('School').max(255),
			board: Yup.string().required().label('Board').max(255),
			main_subjects: Yup.string()
				.required()
				.label('Selected subjects')
				.max(255),
			year_of_passing: Yup.string().required().label('Year of passing').max(4),
			score: Yup.string().required().label('Score/Grade').max(10),
		}),
		graduation: Yup.object().shape({
			institute: Yup.string().required().label('Institute').max(255),
			degree: Yup.string().required().label('Degree').max(255),
			specialization: Yup.string().required().label('Specialization').max(255),
			year_of_passing: Yup.string().required().label('Year of passing').max(4),
			university: Yup.string().required().label('University').max(255),
		}),
	});

	const submitFunction = (values, setStatus, setErrors) => {
		if (_.isEqual(initialValues, values)) {
			return history.push(
				`/admission-form/${user.applying_for}/${
					user.applying_for === 'pgdm' ? 'uploads' : 'work-experience-details'
				}`
			);
		}
		if (user.current_form > 2) {
			return putAcademicDetails(values)
				.then((res) => {
					history.push(`/admission-form/${user.applying_for}/address-details`);
				})
				.catch((err) => {
					if (err?.response?.data?.errors) {
						return setErrors(err.response.data.errors);
					}
					return setStatus(err.response.data.message);
				});
		}
		return addAcademicDetails(values)
			.then((res) => {
				dispatch(updateUser({ current_form: res.data.current_form }));
				history.push(
					`/admission-form/${user.applying_for}/${
						steps[user.applying_for][res.data.current_form]
					}`
				);
			})
			.catch((err) => {
				if (err?.response?.data?.errors) {
					return setErrors(err.response.data.errors);
				}
				return setStatus(err.response.data.message);
			});
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: (values, { setStatus, setErrors }) => {
			return submitFunction(values, setStatus, setErrors);
		},
	});

	const startYear = 2000;
	const endYear = new Date().getFullYear();

	useEffect(() => {
		if (user.current_form < 2) {
			history.push(
				`/admission-form/${user.applying_for}/${
					steps[user.applying_for][user.current_form]
				}`
			);
		} else {
			getAcademicDetails()
				.then((res) => {
					setinitialValues(res.data);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [history, user]);

	return (
		<Box px={{ base: '2', md: '10' }}>
			<form onSubmit={formik.handleSubmit}>
				<Heading as='h4' size='xl' mb='10'>
					Step 3: Academic Details
				</Heading>
				<SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} spacing={10} mb='10'>
					<GridItem colSpan={{ base: 1, md: 2, xl: 3 }}>
						<Text
							fontSize='sm'
							color='gray.500'
							textDecoration='underline'
							mb='2'
						>
							SSC Information
						</Text>
						{formik.status && (
							<Alert status='error' mb='5'>
								<AlertIcon />
								<AlertDescription>{formik.status}</AlertDescription>
							</Alert>
						)}
					</GridItem>
					<FormControl
						id='ssc.school'
						mb='5'
						isInvalid={formik.errors.ssc?.school}
						isRequired
					>
						<FormLabel>School</FormLabel>
						<Input type='text' {...formik.getFieldProps('ssc.school')} />
						<FormErrorMessage>{formik.errors.ssc?.school}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='ssc.board'
						mb='5'
						isInvalid={formik.errors.ssc?.board}
						isRequired
					>
						<FormLabel>Board</FormLabel>
						<Input type='text' {...formik.getFieldProps('ssc.board')} />
						<FormErrorMessage>{formik.errors.ssc?.board}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='ssc.year_of_passing'
						mb='5'
						isInvalid={formik.errors.ssc?.year_of_passing}
						isRequired
					>
						<FormLabel>Year of passing</FormLabel>
						<Select
							placeholder='Select an option'
							value={formik.values.ssc?.year_of_passing}
							onChange={(e) =>
								formik.setFieldValue('ssc.year_of_passing', e.target.value)
							}
						>
							{[...Array(endYear - startYear)].map((value, index) => {
								return (
									<option key={index} value={startYear + index}>
										{startYear + index}
									</option>
								);
							})}
						</Select>
						<FormErrorMessage>
							{formik.errors.ssc?.year_of_passing}
						</FormErrorMessage>
					</FormControl>
					<FormControl
						id='ssc.score'
						mb='5'
						isInvalid={formik.errors.ssc?.score}
						isRequired
					>
						<FormLabel>Overall Grade/Percentage</FormLabel>
						<Input type='text' {...formik.getFieldProps('ssc.score')} />
						<FormErrorMessage>{formik.errors.ssc?.score}</FormErrorMessage>
					</FormControl>
					<GridItem colSpan={{ base: 1, md: 2, xl: 3 }}>
						<Text fontSize='sm' color='gray.500' textDecoration='underline'>
							HSC Information
						</Text>
					</GridItem>
					<FormControl
						id='hsc.school'
						mb='5'
						isInvalid={formik.errors.hsc?.school}
						isRequired
					>
						<FormLabel>School</FormLabel>
						<Input type='text' {...formik.getFieldProps('hsc.school')} />
						<FormErrorMessage>{formik.errors.hsc?.school}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='hsc.board'
						mb='5'
						isInvalid={formik.errors.hsc?.board}
						isRequired
					>
						<FormLabel>Board</FormLabel>
						<Input type='text' {...formik.getFieldProps('hsc.board')} />
						<FormErrorMessage>{formik.errors.hsc?.board}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='hsc.main_subjects'
						mb='5'
						isInvalid={formik.errors.hsc?.main_subjects}
						isRequired
					>
						<FormLabel>Selected subjects</FormLabel>
						<Input type='text' {...formik.getFieldProps('hsc.main_subjects')} />
						<FormErrorMessage>
							{formik.errors.hsc?.main_subjects}
						</FormErrorMessage>
					</FormControl>
					<FormControl
						id='hsc.year_of_passing'
						mb='5'
						isInvalid={formik.errors.hsc?.year_of_passing}
						isRequired
					>
						<FormLabel>Year of passing</FormLabel>
						<Select
							placeholder='Select an option'
							value={formik.values.hsc?.year_of_passing}
							onChange={(e) =>
								formik.setFieldValue('hsc.year_of_passing', e.target.value)
							}
						>
							{[...Array(endYear - startYear)].map((value, index) => {
								return (
									<option key={index} value={startYear + index}>
										{startYear + index}
									</option>
								);
							})}
						</Select>
						<FormErrorMessage>
							{formik.errors.hsc?.year_of_passing}
						</FormErrorMessage>
					</FormControl>
					<FormControl
						id='hsc.score'
						mb='5'
						isInvalid={formik.errors.hsc?.score}
						isRequired
					>
						<FormLabel>Overall Grade/Percentage</FormLabel>
						<Input type='text' {...formik.getFieldProps('hsc.score')} />
						<FormErrorMessage>{formik.errors.hsc?.score}</FormErrorMessage>
					</FormControl>
					<GridItem colSpan={{ base: 1, md: 2, xl: 3 }}>
						<Text fontSize='sm' color='gray.500' textDecoration='underline'>
							Graduation Information
						</Text>
					</GridItem>
					<FormControl
						id='graduation.institute'
						mb='5'
						isInvalid={formik.errors.graduation?.institute}
						isRequired
					>
						<FormLabel>Institute</FormLabel>
						<Input
							type='text'
							{...formik.getFieldProps('graduation.institute')}
						/>
						<FormErrorMessage>
							{formik.errors.graduation?.institute}
						</FormErrorMessage>
					</FormControl>
					<FormControl
						id='graduation.degree'
						mb='5'
						isInvalid={formik.errors.graduation?.degree}
						isRequired
					>
						<FormLabel>Degree</FormLabel>
						<Input type='text' {...formik.getFieldProps('graduation.degree')} />
						<FormErrorMessage>
							{formik.errors.graduation?.degree}
						</FormErrorMessage>
					</FormControl>
					<FormControl
						id='graduation.specialization'
						mb='5'
						isInvalid={formik.errors.graduation?.specialization}
						isRequired
					>
						<FormLabel>Specialization</FormLabel>
						<Input
							type='text'
							{...formik.getFieldProps('graduation.specialization')}
						/>
						<FormErrorMessage>
							{formik.errors.graduation?.specialization}
						</FormErrorMessage>
					</FormControl>
					<FormControl
						id='graduation.year_of_passing'
						mb='5'
						isInvalid={formik.errors.graduation?.year_of_passing}
						isRequired
					>
						<FormLabel>Year of passing</FormLabel>
						<Select
							placeholder='Select an option'
							value={formik.values.graduation?.year_of_passing}
							onChange={(e) =>
								formik.setFieldValue(
									'graduation.year_of_passing',
									e.target.value
								)
							}
						>
							{[...Array(30)].map((value, index) => {
								return (
									<option key={index} value={startYear + index}>
										{startYear + index}
									</option>
								);
							})}
						</Select>
						<FormErrorMessage>
							{formik.errors.graduation?.year_of_passing}
						</FormErrorMessage>
					</FormControl>
					<FormControl
						id='graduation.university'
						mb='5'
						isInvalid={formik.errors.graduation?.university}
						isRequired
					>
						<FormLabel>University</FormLabel>
						<Input
							type='text'
							{...formik.getFieldProps('graduation.university')}
						/>
						<FormErrorMessage>
							{formik.errors.graduation?.university}
						</FormErrorMessage>
					</FormControl>
				</SimpleGrid>
				<Flex justifyContent='space-between' alignItems='center'>
					<Button
						as={Link}
						colorScheme='gray'
						leftIcon={<ArrowBackIcon />}
						to={`/admission-form/${user.applying_for}/address-details`}
					>
						Previous Step
					</Button>
					<Button
						type='submit'
						colorScheme='green'
						rightIcon={<ArrowForwardIcon />}
						isLoading={formik.isSubmitting}
						spinnerPlacement='end'
					>
						Save and proceed
					</Button>
				</Flex>
				{/* <pre>{JSON.stringify(formik.values, null, 2)}</pre>
				<br />
				<pre>{JSON.stringify(formik.errors, null, 2)}</pre> */}
			</form>
		</Box>
	);
}
