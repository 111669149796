import {
	Box,
	Button,
	Flex,
	Heading,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import { getComments, patchComment } from './redux/adminAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck,
} from '@fortawesome/free-solid-svg-icons';

export const AdminComments = () => {
	const [next, setnext] = useState(2);
	const [prev, setprev] = useState(null);
	const [blogs, setblogs] = useState(null);
	const [showMessage, setshowMessage] = useState(null);

	const getData = async (page) => {
		await getComments({ limit: 10, page })
			.then((res) => {
				setblogs(res.data.comments);
				setprev(res.data.prev);
				setnext(res.data.next);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleShowMessage = (id) => {
		if (showMessage === id) {
			return setshowMessage(null);
		}
		return setshowMessage(id);
	};

	const approveComment = async (id,approved) => {
		await patchComment({id:id,approved:(approved==1)?0:1}).then((res) => {
			let msg = ' Comment has been'+((approved==1)?'disapproved':'approved')+' successfully.';
			alert('Comment approved successfully.');
		})
		.catch((err) => {
			console.log(err);
		});
	}

	useEffect(() => {
		const getInitialData = async () => {
			await getComments({ limit: 10, page: 1 })
				.then((res) => {
					setblogs(res.data.comments);
					setprev(res.data.prev);
					setnext(res.data.next);
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getInitialData();
	}, []);

	return (
		<Box>
			<Heading size='xl' mb='6'>
				Comments
			</Heading>
			<Box overflow='auto' mb='4'>
				<Table variant='simple'>
					<Thead>
						<Tr>
							<Th>Sr</Th>
							{/* <Th>Blog Title</Th> */}
							<Th>Commentor</Th>
							<Th>Body</Th>
							<Th>Date</Th>
							<Th>Action</Th>
						</Tr>
					</Thead>
					<Tbody>
						{blogs && blogs.length > 0 ? (
							blogs.map((eq, index) => {
								return (
									<Fragment key={eq.id}>
										<Tr
											onClick={() => handleShowMessage(eq.id)}
											_hover={{ bg: 'gray.100', cursor: 'pointer' }}
										>
											<Td borderBottom='none'>{index + 1}</Td>
											{/* <Td borderBottom='none'>{eq.title}</Td> */}
											<Td borderBottom='none'>{eq.commentor}</Td>
											<Td borderBottom='none'>{eq.body}</Td>
											<Td borderBottom='none'>
												{format(parseISO(eq.createdAt), 'dd-MM-yyyy')}
											</Td>
											<Td>
												<Button className="btn btn-success" title="Approve Comment" onClick={() => approveComment(eq.id,eq.approved)}><FontAwesomeIcon icon={faCheck} /></Button>
											</Td>
										</Tr>
									</Fragment>
								);
							})
						) : (
							<Tr>
								<Td colSpan={4}>No Comments</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
			<Flex justifyContent='flex-end'>
				<Button
					disabled={!prev}
					colorScheme='gray'
					variant='outline'
					rounded='none'
					roundedLeft='lg'
					onClick={() => getData(prev)}
				>
					Previous
				</Button>
				<Button as={Text} colorScheme='blue' rounded='none'>
					{next ? next - 1 : prev ? prev + 1 : 1}
				</Button>
				<Button
					disabled={!next}
					colorScheme='gray'
					variant='outline'
					rounded='none'
					roundedRight='lg'
					onClick={() => getData(next)}
				>
					Next
				</Button>
			</Flex>
		</Box>
	);
};
