import {
	Box,
	Heading,
	Link,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	Button,
	Alert,
	AlertIcon,
	FormHelperText,
} from '@chakra-ui/react';
import { useFormik, FormikProvider } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { resetPassword } from './redux/authAPI';
import { setAuthMsg } from './redux/userRedux';
import jwt_decode from 'jwt-decode';
import { passwordPattern } from '../../utils/constants';

export default function ResetPassword() {
	const { resetToken } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const [studentId, setstudentId] = useState();

	const initialValues = {
		password: '',
		confirmPassword: '',
	};

	const validationSchema = Yup.object().shape({
		password: Yup.string()
			.test('match-regex', (value) => passwordPattern.test(value))
			.required(),
		confirmPassword: Yup.string().test(
			'passwords-match',
			'Passwords must match',
			function (value) {
				return this.parent.password === value;
			}
		),
	});

	const submitFunction = (values, setStatus) => {
		let temp = values;
		delete temp.confirmPassword;
		temp.resetToken = resetToken;
		temp.studentId = studentId;
		return resetPassword(values)
			.then((res) => {
				dispatch(
					setAuthMsg('Password changed successfully, please login to continue')
				);
				history.push('/login');
			})
			.catch((err) => {
				setStatus(err.response.data.message);
			});
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		validateOnBlur: false,
		validateOnChange: false,
		enableReinitialize: true,
		onSubmit: (values, { setStatus }) => {
			return submitFunction(values, setStatus);
		},
	});

	useEffect(() => {
		if (!resetToken) {
			history.push('/login');
		}
		if (resetToken) {
			try {
				const decoded = jwt_decode(resetToken);
				setstudentId(decoded.userId);
			} catch (err) {
				history.push('/login');
			}
		}
	}, [resetToken, history]);

	return (
		<>
			<Box
				position='absolute'
				top='20px'
				right='20px'
				display={{ base: 'none', md: 'block' }}
			>
				Go to login,{` `}
				<Link color='teal.500' as={NavLink} to='/login'>
					Click here
				</Link>
			</Box>
			<Box width='90%' maxWidth='350px'>
				<form onSubmit={formik.handleSubmit}>
					<FormikProvider value={formik}>
						<Heading
							as='h4'
							size='xl'
							color='blue.700'
							textAlign='center'
							mb='5'
						>
							Forgot Password
						</Heading>
						{formik.status && (
							<Alert status='info' mb='5'>
								<AlertIcon />
								{formik.status}
							</Alert>
						)}
						<FormControl
							id='password'
							mb='5'
							isInvalid={formik.errors.password}
							isRequired
						>
							<FormLabel>Password</FormLabel>
							<Input
								type='password'
								{...formik.getFieldProps('password')}
								autoFocus={true}
							/>
							<FormHelperText
								color={formik.errors.password ? 'red.500' : 'gray.500'}
							>
								Password must be minimum 8 characters and have at least 1
								special character, 1 uppercase alphabet, 1 lowercase alphabet
								and 1 number
							</FormHelperText>
						</FormControl>
						<FormControl
							id='confirmPassword'
							mb='5'
							isInvalid={formik.errors.confirmPassword}
							isRequired
						>
							<FormLabel>Confirm Password</FormLabel>
							<Input
								type='password'
								{...formik.getFieldProps('confirmPassword')}
							/>
							<FormErrorMessage>
								{formik.errors.confirmPassword}
							</FormErrorMessage>
						</FormControl>

						<Button
							type='submit'
							colorScheme='blue'
							width={{ base: '100%', md: 'auto' }}
							loadingText='Submit'
							spinnerPlacement='end'
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
						<Box
							display={{ base: 'block', md: 'none' }}
							mt='5'
							textAlign='center'
						>
							Go to login,{` `}
							<Link color='teal.500' as={NavLink} to='/login'>
								Click here
							</Link>
						</Box>
					</FormikProvider>
				</form>
			</Box>
		</>
	);
}
