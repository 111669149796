import { Input, Box, Center, Text } from '@chakra-ui/react';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDropzone } from 'react-dropzone';

export default function FileUpload({ name, isMultiple, handleChange, accept }) {
	const { getInputProps, isDragActive, getRootProps } = useDropzone({
		multiple: isMultiple,
		onDrop: (files) => handleChange(files, name),
		//accept: accept,
	});
	let temp = '';
	accept.split(',').forEach((val, index) => {
		if (index > 0) {
			temp = temp.concat(`, ${val.split('/')[1]}`);
		} else {
			temp = temp.concat(val.split('/')[1]);
		}
	});

	return (
		<>
			<Center
				width='100%'
				{...getRootProps({ className: 'dropzone' })}
				border='1px solid'
				borderColor='gray.300'
				rounded='lg'
				h='36'
				px='4'
				cursor='pointer'
				flexDirection='column'
				color={isDragActive ? 'gray.400' : 'gray.600'}
			>
				<Input name={name} {...getInputProps()} />
				<Box fontSize='2xl'>
					<FontAwesomeIcon icon={faFileUpload} />
				</Box>
				<Text>Drop the file here or click to upload</Text>
				<Text>Allowed files: {temp}</Text>
			</Center>
		</>
	);
}
