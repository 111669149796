import { DownloadIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getZip } from '../../admissionform/redux/AdmissionAPI';

export const FileUploadsTable = ({ ups, email }) => {
	const { student_id } = useParams();
	const downloadFile = () => {
		return getZip({ student_id })
			.then((res) => {
				const data = res.data; // or res.blob() if using blob responses

				const url = window.URL.createObjectURL(
					new Blob([data], {
						type: res.headers['content-type'],
					})
				);

				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${email.split('@')[0]}.zip`);
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	if (!ups) {
		return (
			<Box>
				<Heading size='md' mb='3'>
					Files Uploaded
				</Heading>
				<Text>Student has not completed this part of the form yet</Text>
			</Box>
		);
	}
	return (
		<Box overflow='auto' mb='6'>
			<Flex>
				<Button
					colorScheme='blue'
					variant='solid'
					type='button'
					rightIcon={<DownloadIcon />}
					onClick={() => downloadFile()}
				>
					Download Students Files (zip)
				</Button>
			</Flex>
		</Box>
	);
};
