import {
	Box,
	Button,
	Flex,
	Heading,
	Table,
	Tag,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUsers } from './redux/adminAPI';

export const AdminUsers = () => {
	const history = useHistory();
	const [next, setnext] = useState(2);
	const [prev, setprev] = useState(null);
	const [users, setusers] = useState(null);

	const getData = async (page) => {
		await getUsers({ limit: 10, page })
			.then((res) => {
				setusers(res.data.students);
				setprev(res.data.prev);
				setnext(res.data.next);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		const getInitialData = async () => {
			await getUsers({ limit: 10, page: 1 })
				.then((res) => {
					setusers(res.data.students);
					setprev(res.data.prev);
					setnext(res.data.next);
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getInitialData();
	}, []);

	return (
		<Box>
			<Heading size='xl' mb='6'>
				Users
			</Heading>
			<Box overflowX='auto' mb='4'>
				<Table variant='simple'>
					<Thead>
						<Tr>
							<Th>Sr</Th>
							<Th>Name</Th>
							<Th>Email</Th>
							<Th>Phone</Th>
							<Th>Applying</Th>
							<Th>Form status</Th>
							<Th>City</Th>
							<Th>Registered on</Th>
						</Tr>
					</Thead>
					<Tbody>
						{users && users.length > 0 ? (
							users.map((user, index) => {
								return (
									<Fragment key={user.student_id}>
										<Tr
											_hover={{ bg: 'gray.100', cursor: 'pointer' }}
											onClick={() =>
												history.push(`/administration/users/${user.student_id}`)
											}
										>
											<Td borderBottom='none'>{index + 1}</Td>
											<Td borderBottom='none'>{user.full_name}</Td>
											<Td borderBottom='none'>{user.email}</Td>
											<Td borderBottom='none'>{user.phone}</Td>
											<Td borderBottom='none'>{user.applying_for}</Td>
											<Td borderBottom='none'>
												{user.is_form_submitted ? (
													<Tag colorScheme='green'>Submitted</Tag>
												) : (
													<Tag colorScheme='red'>Pending</Tag>
												)}
											</Td>
											<Td borderBottom='none'>{user.city}</Td>
											<Td borderBottom='none'>
												{format(parseISO(user.createdAt), 'dd-MM-yyyy')}
											</Td>
										</Tr>
									</Fragment>
								);
							})
						) : (
							<Tr>
								<Td colSpan={4}>No users</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
			<Flex justifyContent='flex-end'>
				<Button
					disabled={!prev}
					colorScheme='gray'
					variant='outline'
					rounded='none'
					roundedLeft='lg'
					onClick={() => getData(prev)}
				>
					Previous
				</Button>
				<Button as={Text} colorScheme='blue' rounded='none'>
					{next ? next - 1 : prev ? prev + 1 : 1}
				</Button>
				<Button
					disabled={!next}
					colorScheme='gray'
					variant='outline'
					rounded='none'
					roundedRight='lg'
					onClick={() => getData(next)}
				>
					Next
				</Button>
			</Flex>
		</Box>
	);
};
