import React, { useEffect, useState } from 'react';
import {
	Box,
	Heading,
	SimpleGrid,
	GridItem,
	Text,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	Button,
	Select,
	CircularProgress,
	Center,
	Flex,
	Alert,
	AlertIcon,
	AlertDescription,
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { useFormik } from 'formik';
import { getCitiesByState, getStates } from '../../../utils/locationAPI';
import * as Yup from 'yup';
import {
	addAddressDetails,
	getAddressDetails,
	putAddressDetails,
} from '../redux/AdmissionAPI';
import { steps } from '../../../utils/constants';
import { updateUser } from '../../auth/redux/userRedux';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

export default function AddressDetails() {
	const [smallLoading, setsmallLoading] = useState('state');
	const [states, setstates] = useState([]);
	const [cities, setcities] = useState([]);

	const { user } = useSelector((state) => state.auth);

	const history = useHistory();
	const dispatch = useDispatch();

	const [initialValues, setinitialValues] = useState({
		address_line_1: '',
		address_line_2: '',
		state: '',
		city: '',
		district: '',
		pincode: '',
	});

	const validationSchema = Yup.object().shape({
		address_line_1: Yup.string()
			.required()
			.label('Address line 1')
			.min(15, 'Must be at least 15 characters long'),
		address_line_2: Yup.string().label('Address line 2'),
		state: Yup.string().required().label('State'),
		city: Yup.string().required().label('City'),
		district: Yup.string().required().max(50).label('District'),
		pincode: Yup.number()
			.test('length', 'Pin code should be 6 digits long', (value) => {
				if (value) {
					return value.toString().length === 6;
				}
				return false;
			})
			.required()
			.label('Pincode')
			.typeError('Must be digits only'),
	});

	const submitFunction = (values, setStatus, setErrors) => {
		if (_.isEqual(initialValues, values)) {
			return history.push(
				`/admission-form/${user.applying_for}/academic-details`
			);
		}
		if (user.current_form > 1) {
			return putAddressDetails(values)
				.then((res) => {
					history.push(`/admission-form/${user.applying_for}/academic-details`);
				})
				.catch((err) => {
					if (err?.response?.data?.errors) {
						return setErrors(err.response.data.errors);
					}
					return setStatus(err.response.data.message);
				});
		}
		return addAddressDetails(values)
			.then((res) => {
				dispatch(updateUser({ current_form: res.data.current_form }));
				history.push(
					`/admission-form/${user.applying_for}/${
						steps[user.applying_for][res.data.current_form]
					}`
				);
			})
			.catch((err) => {
				if (err?.response?.data?.errors) {
					return setErrors(err.response.data.errors);
				}
				return setStatus(err.response.data.message);
			});
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: (values, { setStatus, setErrors }) => {
			return submitFunction(values, setStatus, setErrors);
		},
	});

	const handleStateChange = async (value) => {
		setsmallLoading('city');
		formik.setValues({
			...formik.values,
			pincode: '',
			district: '',
			city: '',
			state: value,
		});
		await getCitiesByState(value)
			.then((res) => {
				setcities(res.data.cities);
			})
			.catch((err) => {
				console.log(err);
			});
		setsmallLoading(null);
	};

	useEffect(() => {
		const getInitialData = () => {
			getStates()
				.then((res) => {
					setstates(res.data.states);
					setsmallLoading(null);
					if (user.current_form > 1) {
						getAddressDetails().then((res) => {
							handleStateChange(res.data.addressDetails.state).then(() => {
								setinitialValues(res.data.addressDetails);
							});
						});
					}
				})
				.catch((err) => {
					console.log(err);
				});
		};
		if (user.current_form < 1) {
			history.push(
				`/admission-form/${user.applying_for}/${
					steps[user.applying_for][user.current_form]
				}`
			);
		} else {
			getInitialData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, user]);

	return (
		<Box px={{ base: '2', md: '10' }}>
			<form onSubmit={formik.handleSubmit}>
				<Heading as='h4' size='xl' mb='10'>
					Step 2: Address Details
				</Heading>
				<SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} spacing={10} mb='10'>
					<GridItem colSpan={{ base: 1, md: 2, xl: 3 }}>
						<Text
							fontSize='sm'
							color='gray.500'
							textDecoration='underline'
							mb='2'
						>
							Address Information
						</Text>
						{formik.status && (
							<Alert status='error' mb='5'>
								<AlertIcon />
								<AlertDescription>{formik.status}</AlertDescription>
							</Alert>
						)}
					</GridItem>
					<FormControl
						id='address_line_1'
						mb='5'
						isInvalid={formik.errors.address_line_1}
						isRequired
					>
						<FormLabel>Address line 1</FormLabel>
						<Input type='text' {...formik.getFieldProps('address_line_1')} />
						<FormErrorMessage>{formik.errors.address_line_1}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='address_line_2'
						mb='5'
						isInvalid={formik.errors.address_line_2}
					>
						<FormLabel>Address Line 2</FormLabel>
						<Input type='text' {...formik.getFieldProps('address_line_2')} />
						<FormErrorMessage>{formik.errors.address_line_2}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='state'
						mb='5'
						isInvalid={formik.errors.state}
						position='relative'
						isRequired
					>
						{smallLoading === 'state' && (
							<Center
								position='absolute'
								width='100%'
								height='100%'
								top='0'
								left='0'
								zIndex='2'
								backgroundColor='rgba(255,255,255,0.6)'
							>
								<CircularProgress size='35px' isIndeterminate />
							</Center>
						)}
						<FormLabel>State</FormLabel>
						<Select
							placeholder='Select option'
							{...formik.getFieldProps('state')}
							onChange={(e) => handleStateChange(e.target.value)}
						>
							{states.map((state, index) => {
								return (
									<option key={index} value={state.state}>
										{state.state}
									</option>
								);
							})}
						</Select>
						<FormErrorMessage>{formik.errors.state}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='city'
						mb='5'
						isInvalid={formik.errors.city}
						position='relative'
						isRequired
					>
						{smallLoading === 'city' && (
							<Center
								position='absolute'
								width='100%'
								height='100%'
								top='0'
								left='0'
								zIndex='2'
								backgroundColor='rgba(255,255,255,0.6)'
							>
								<CircularProgress size='35px' isIndeterminate />
							</Center>
						)}
						<FormLabel>City</FormLabel>
						<Select
							placeholder='Select option'
							{...formik.getFieldProps('city')}
							disabled={cities.length === 0}
						>
							{cities &&
								cities.map((city, index) => {
									return (
										<option key={index} value={city.city}>
											{city.city}
										</option>
									);
								})}
						</Select>
						<FormErrorMessage>{formik.errors.city}</FormErrorMessage>
					</FormControl>

					<FormControl
						id='district'
						mb='5'
						isInvalid={formik.errors.district}
						position='relative'
						isRequired
					>
						<FormLabel>District</FormLabel>
						<Input type='text' {...formik.getFieldProps('district')} />
						<FormErrorMessage>{formik.errors.district}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='pincode'
						mb='5'
						isInvalid={formik.errors.pincode}
						position='relative'
						isRequired
					>
						<FormLabel>Pin Code</FormLabel>
						<Input type='text' {...formik.getFieldProps('pincode')} />
						<FormErrorMessage>{formik.errors.pincode}</FormErrorMessage>
					</FormControl>
				</SimpleGrid>
				<Flex justifyContent='space-between' alignItems='center'>
					<Button
						as={Link}
						colorScheme='gray'
						leftIcon={<ArrowBackIcon />}
						to={`/admission-form/${user.applying_for}/personal-details`}
					>
						Previous Step
					</Button>
					<Button
						type='submit'
						colorScheme='green'
						rightIcon={<ArrowForwardIcon />}
						isLoading={formik.isSubmitting}
						spinnerPlacement='end'
					>
						Save and proceed
					</Button>
				</Flex>
				{/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
			</form>
		</Box>
	);
}
