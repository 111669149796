import axios from 'axios';

export const getStates = () => {
	return axios.get('/location/states');
};

export const getCitiesByState = (state) => {
	return axios.get('/location/cities', { params: { state } });
};

export const getDistrictsByCity = (city) => {
	return axios.get('/location/districts', { params: { city } });
};

export const getPincodesByDistrict = (district) => {
	return axios.get('/location/pincodes', { params: { district } });
};
