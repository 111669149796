import { Box, Flex, Text } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { ProfileHeader } from './components/ProfileHeader';

export const Profile = () => {
	const { user } = useSelector((state) => state.auth);
	return (
		<Box width='100%' maxWidth='500px'>
			<ProfileHeader heading='My Profile' />
			<Flex mb='1' fontSize='lg' justifyContent='space-between'>
				<Box minWidth='32'>
					<Text>Name:</Text>
				</Box>
				<Text>{user.full_name}</Text>
			</Flex>
			<Flex mb='1' fontSize='lg' justifyContent='space-between'>
				<Box minWidth='32'>
					<Text>Email:</Text>
				</Box>
				<Text>{user.email}</Text>
			</Flex>
			<Flex mb='1' fontSize='lg' justifyContent='space-between'>
				<Box minWidth='32'>
					<Text>City:</Text>
				</Box>
				<Text>{user.city}</Text>
			</Flex>
			<Flex mb='1' fontSize='lg' justifyContent='space-between'>
				<Box minWidth='32'>
					<Text>Registered on:</Text>
				</Box>
				<Text>{format(parseISO(user.createdAt), 'dd-MM-yyyy')}</Text>
			</Flex>
		</Box>
	);
};
