import { Box, Center, Flex, Image } from '@chakra-ui/react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ForgotPassword from '../pages/auth/ForgotPassword';
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import ResetPassword from '../pages/auth/ResetPassword';
import VerifyEmail from '../pages/auth/VerifyEmail';

export default function UnauthenticatedRoutes() {
	return (
		<Flex height='100vh'>
			<Box
				height='100vh'
				width='30%'
				display={{ base: 'none', lg: 'block' }}
				backgroundColor='blue.100'
			></Box>
			<Center height='100vh' flexGrow='1' flexDirection='column'>
				<Box>
					<Image
						width='350px'
						src={`${process.env.PUBLIC_URL}/media/logo.png`}
						alt=''
						mb='6'
					/>
				</Box>
				<Switch>
					<Route exact path='/login' component={Login} />
					<Route exact path='/register' component={Register} />
					<Route exact path='/forgot-password' component={ForgotPassword} />
					<Route
						exact
						path='/verify-email/:emailToken'
						component={VerifyEmail}
					/>
					<Route
						exact
						path='/reset-password/:resetToken'
						component={ResetPassword}
					/>
					<Redirect to='/login' />
				</Switch>
			</Center>
		</Flex>
	);
}
