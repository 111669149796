import {
	Alert,
	AlertDescription,
	AlertIcon,
	Box,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
} from '@chakra-ui/react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React from 'react';
import { useState } from 'react';
import { ProfileHeader } from './components/ProfileHeader';
import * as Yup from 'yup';
import { passwordPattern } from '../../utils/constants';
import { changePassword } from '../auth/redux/authAPI';

export const ChangePassword = () => {
	const [showCurrentPassword, setshowCurrentPassword] = useState(false);
	const [showNewPassword, setshowNewPassword] = useState(false);
	const [alertStatus, setalertStatus] = useState('info');
	const initialValues = {
		password: '',
		newPassword: '',
		confirmNewPassword: '',
	};
	const validationSchema = Yup.object().shape({
		password: Yup.string().required(),
		newPassword: Yup.string()
			.test('regex', 'Password does not match all conditions', (value) =>
				passwordPattern.test(value)
			)
			.notOneOf([Yup.ref('password')], 'Should not be same as password'),
		confirmNewPassword: Yup.string().oneOf(
			[Yup.ref('newPassword')],
			'New password and confirm new password do not match'
		),
	});
	const submitFunction = (values, setStatus, setErrors) => {
		return changePassword(values)
			.then((res) => {
				setalertStatus('success');
				setStatus('Password changed successfully');
			})
			.catch((err) => {
				setalertStatus('error');
				if (err?.response?.data?.errors) {
					setErrors(err.response.data.errors);
				} else {
					setStatus(err?.response?.data?.message);
				}
			});
	};
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values, { setStatus, setErrors }) => {
			return submitFunction(values, setStatus, setErrors);
		},
	});
	return (
		<Box maxWidth='500px'>
			<ProfileHeader heading='Change Password' />
			{formik.status && (
				<Alert status={alertStatus} mb='5'>
					<AlertIcon />
					<AlertDescription>{formik.status}</AlertDescription>
				</Alert>
			)}
			<form onSubmit={formik.handleSubmit}>
				<FormControl
					mb='5'
					isInvalid={formik.errors.password && formik.touched.password}
				>
					<FormLabel>Current Password</FormLabel>
					<InputGroup>
						<Input
							type={showCurrentPassword ? 'text' : 'password'}
							{...formik.getFieldProps('password')}
						/>
						<InputRightElement>
							<Button
								type='button'
								onClick={() => setshowCurrentPassword(!showCurrentPassword)}
								size='sm'
								position='relative'
							>
								<Box
									pos='absolute'
									opacity={showCurrentPassword ? '0' : '1'}
									transition='all'
									transitionDuration='100ms'
								>
									<FontAwesomeIcon icon={faEye} />
								</Box>
								<Box
									pos='absolute'
									opacity={showCurrentPassword ? '1' : '0'}
									transition='all'
									transitionDuration='100ms'
								>
									<FontAwesomeIcon icon={faEyeSlash} />
								</Box>
							</Button>
						</InputRightElement>
					</InputGroup>
					<FormErrorMessage>{formik.errors.password}</FormErrorMessage>
				</FormControl>
				<FormControl
					mb='5'
					isInvalid={formik.errors.newPassword && formik.touched.newPassword}
				>
					<FormLabel>New Password</FormLabel>
					<InputGroup>
						<Input
							type={showNewPassword ? 'text' : 'password'}
							{...formik.getFieldProps('newPassword')}
						/>
						<InputRightElement>
							<Button
								type='button'
								onClick={() => setshowNewPassword(!showNewPassword)}
								size='sm'
								position='relative'
							>
								<Box
									pos='absolute'
									opacity={showNewPassword ? '0' : '1'}
									transition='all'
									transitionDuration='100ms'
								>
									<FontAwesomeIcon icon={faEye} />
								</Box>
								<Box
									pos='absolute'
									opacity={showNewPassword ? '1' : '0'}
									transition='all'
									transitionDuration='100ms'
								>
									<FontAwesomeIcon icon={faEyeSlash} />
								</Box>
							</Button>
						</InputRightElement>
					</InputGroup>
					<FormErrorMessage>{formik.errors.newPassword}</FormErrorMessage>
				</FormControl>
				<FormControl
					mb='5'
					isInvalid={
						formik.errors.confirmNewPassword &&
						formik.touched.confirmNewPassword
					}
				>
					<FormLabel>Confirm New Password</FormLabel>
					<InputGroup>
						<Input
							type={showNewPassword ? 'text' : 'password'}
							{...formik.getFieldProps('confirmNewPassword')}
						/>
					</InputGroup>
					<FormErrorMessage>
						{formik.errors.confirmNewPassword}
					</FormErrorMessage>
				</FormControl>
				<Box textAlign='right'>
					<Button type='submit' colorScheme='blue'>
						Submit
					</Button>
				</Box>
			</form>
			{/* <pre>{JSON.stringify(formik.values, null, 2)}</pre>
			<br />
			<pre>{JSON.stringify(formik.errors, null, 2)}</pre> */}
		</Box>
	);
};
