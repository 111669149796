import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import NotFound from '../layout/NotFound';
import { ChangePassword } from './ChangePassword';
import { Profile } from './Profile';

export const UserPage = () => {
	const { user } = useSelector((state) => state.auth);
	return (
		<Switch>
			{user && (
				<>
					<Route>
						<Switch>
							<Redirect exact from='/user' to='/user/profile' />
							<Route exact path={`/user/profile`} component={Profile} />
							<Route
								exact
								path={`/user/change-password`}
								component={ChangePassword}
							/>
							<Route component={NotFound} />
						</Switch>
					</Route>
				</>
			)}
		</Switch>
	);
};
