import {
	faBriefcase,
	faFileUpload,
	faGraduationCap,
	faHome,
	faList,
	faUser,
} from '@fortawesome/free-solid-svg-icons';

export const passwordPattern =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const steps = {
	pgdm: [
		'personal-details',
		'address-details',
		'academic-details',
		'uploads',
		'confirmation',
	],
	pgdme: [
		'personal-details',
		'address-details',
		'academic-details',
		'work-experience-details',
		'uploads',
		'confirmation',
	],
};

export const wizardSteps = {
	pgdm: [
		{
			text: 'Personal',
			icon: faUser,
			link: 'personal-details',
		},
		{
			text: 'Address',
			icon: faHome,
			link: 'address-details',
		},
		{
			text: 'Academic',
			icon: faGraduationCap,
			link: 'academic-details',
		},
		{
			text: 'Uploads',
			icon: faFileUpload,
			link: 'uploads',
		},
		{
			text: 'Confirmation',
			icon: faList,
			link: 'confirmation',
		},
	],
	pgdme: [
		{
			text: 'Personal',
			icon: faUser,
			link: 'personal-details',
		},
		{
			text: 'Address',
			icon: faHome,
			link: 'address-details',
		},
		{
			text: 'Academic',
			icon: faGraduationCap,
			link: 'academic-details',
		},
		{
			text: 'Work Experience',
			icon: faBriefcase,
			link: 'work-experience-details',
		},
		{
			text: 'Uploads',
			icon: faFileUpload,
			link: 'uploads',
		},
		{
			text: 'Confirmation',
			icon: faList,
			link: 'confirmation',
		},
	],
};

export const singleUserData = [
	'personal_details',
	'address_details',
	'ssc_details',
	'hsc_details',
	'graduation_details',
	'work_experience_details',
];
