import {
	Box,
	Button,
	Flex,
	Heading,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
} from '@chakra-ui/react';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

export const ProfileHeader = ({ heading }) => {
	return (
		<Flex justifyContent='space-between' alignItems='center' mb='6'>
			<Heading size='xl'>{heading}</Heading>
			<Menu placement='bottom-end'>
				{({ isOpen }) => (
					<>
						<MenuButton as={Button} size='sm'>
							<Box
								transform={isOpen ? 'rotate(90deg)' : ''}
								transition='all'
								transitionDuration='300ms'
							>
								<FontAwesomeIcon icon={faCog} />
							</Box>
						</MenuButton>
						<MenuList>
							<MenuItem as={Link} to='profile'>
								Profile
							</MenuItem>
							<MenuItem as={Link} to='change-password'>
								Change Password
							</MenuItem>
						</MenuList>
					</>
				)}
			</Menu>
		</Flex>
	);
};
