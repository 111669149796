import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrentUser, refreshToken } from './pages/auth/redux/authAPI';
import {
	removeAccessToken,
	removeUser,
	setAccessToken,
	setUser,
} from './pages/auth/redux/userRedux';
import Routes from './routes/Routes';
import FullScreenLoader from './utils/components/FullScreenLoader';

function App() {
	const [loading, setloading] = useState(true);
	const dispatch = useDispatch();
	useEffect(() => {
		const getData = async () => {
			await refreshToken()
				.then(async (res) => {
					dispatch(setAccessToken(res.data.accessToken));
					await getCurrentUser()
						.then((res) => {
							dispatch(setUser(res.data.user));
						})
						.catch((err) => {
							console.log(err);
						});
				})
				.catch((err) => {
					console.log(err);
					dispatch(removeUser);
					dispatch(removeAccessToken);
				});
			setTimeout(() => setloading(false), 500);
		};
		getData();
	}, [dispatch]);

	return <>{loading ? <FullScreenLoader /> : <Routes />}</>;
}

export default App;
