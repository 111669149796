import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from '@chakra-ui/react';
import React from 'react';

export const DynamicModal = ({
	isOpen,
	onClose,
	content,
	onConfirm,
	params,
}) => {
	const confirmFunction = () => {
		onConfirm(params);
		onClose();
	};
	return (
		<>
			<AlertDialog
				motionPreset='slideInBottom'
				onClose={onClose}
				isOpen={isOpen}
				isCentered
			>
				<AlertDialogOverlay />

				<AlertDialogContent>
					<AlertDialogHeader>{content?.head}</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>{content?.body}</AlertDialogBody>
					<AlertDialogFooter>
						<Button onClick={onClose}>
							{content.cancelButton || 'Cancel'}
						</Button>
						<Button colorScheme='red' ml={3} onClick={() => confirmFunction()}>
							{content.confirmButton || 'Confirm'}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
};
