const isObjectKeysEmpty = (obj) => {
	let hasValue = false;
	Object.keys(obj).forEach((key) => {
		if (obj[key]) {
			hasValue = true;
		}
	});
	if (hasValue) {
		return false;
	}
	return true;
};

module.exports = { isObjectKeysEmpty };
