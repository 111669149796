import { Box, Text } from '@chakra-ui/react';
import React from 'react';

export const DisplayField = ({ label, value }) => {
	return (
		<Box>
			<Text color='gray.500' fontSize='sm' display='inline'>
				{label}:{` `}
			</Text>
			<Text display='inline' textTransform='capitalize'>
				{value || '-'}
			</Text>
		</Box>
	);
};
