import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { auth } from '../pages/auth/redux/userRedux';

const middlewares = getDefaultMiddleware();

const reducer = combineReducers({
	auth: auth,
});

const store = configureStore({
	reducer,
	middleware: middlewares,
});

export default store;
