import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const FormSubmitted = () => {
	return (
		<Flex mt='10' direction='column' textAlign='center'>
			<Box color='green.300' mb='4'>
				<FontAwesomeIcon icon={faCheckCircle} size='6x' />
			</Box>
			<Text mb='3' fontSize='lg'>
				Your form has been successfully submitted and is under process.
			</Text>
			<Text mb='3' fontSize='lg'>
				We will keep you informed about your application status via email.
			</Text>
			<Text mb='3' fontSize='lg'>
				If you have a query you can write to us at{' '}
				<Link color='blue.600'>help@admissions.kmmias.org</Link>
			</Text>
		</Flex>
	);
};
