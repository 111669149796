import {
	Box,
	Heading,
	FormControl,
	FormLabel,
	Input,
	Button,
	FormErrorMessage,
	InputGroup,
	InputRightElement,
	Link,
	Alert,
	AlertIcon,
	AlertDescription,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik, FormikProvider } from 'formik';
import React from 'react';
import { useState } from 'react';
import * as Yup from 'yup';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken, setUser } from './redux/userRedux';
import { login } from './redux/authAPI';

export default function Login() {
	const [showPassword, setshowPassword] = useState(false);

	const { authMsg } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	const initialValues = {
		email: '',
		password: '',
	};

	const validationSchema = Yup.object().shape({
		email: Yup.string().email().required(),
		password: Yup.string().required(),
	});

	const submitFunction = (values, setStatus, setErrors) => {
		return login(values)
			.then((res) => {
				dispatch(setUser(res.data.user));
				dispatch(setAccessToken(res.data.accessToken));
			})
			.catch((err) => {
				if (err?.response?.data?.errors) {
					setErrors(err.response.data.errors);
				} else {
					setStatus(err?.response?.data?.message);
				}
			});
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: (values, { setStatus, setErrors }) => {
			return submitFunction(values, setStatus, setErrors);
		},
	});

	return (
		<>
			<Box
				position='absolute'
				top='20px'
				right='20px'
				display={{ base: 'none', md: 'block' }}
			>
				Not registered?{' '}
				<Link color='teal.500' as={NavLink} to='/register'>
					Sign up here
				</Link>
			</Box>
			<Box width='90%' maxWidth='350px'>
				<form onSubmit={formik.handleSubmit}>
					<FormikProvider value={formik}>
						<Heading
							as='h4'
							size='xl'
							color='blue.700'
							textAlign='center'
							mb='5'
						>
							Login
						</Heading>
						{authMsg && (
							<Alert status='info' mb='5'>
								<AlertIcon />
								{authMsg}
							</Alert>
						)}
						{formik.status && (
							<Alert status='error' mb='5'>
								<AlertIcon />
								<AlertDescription>{formik.status}</AlertDescription>
							</Alert>
						)}
						<FormControl
							id='email'
							mb='5'
							isInvalid={formik.errors.email && formik.touched.email}
						>
							<FormLabel>Email address</FormLabel>
							<Input
								type='text'
								autoFocus={true}
								{...formik.getFieldProps('email')}
							/>
							<FormErrorMessage>{formik.errors.email}</FormErrorMessage>
						</FormControl>
						<FormControl
							id='password'
							mb='5'
							isInvalid={formik.errors.password && formik.touched.password}
						>
							<FormLabel>Password</FormLabel>
							<InputGroup>
								<Input
									type={showPassword ? 'text' : 'password'}
									{...formik.getFieldProps('password')}
								/>
								<InputRightElement>
									<Button
										type='button'
										onClick={() => setshowPassword(!showPassword)}
										size='sm'
									>
										{showPassword ? (
											<FontAwesomeIcon icon={faEye} />
										) : (
											<FontAwesomeIcon icon={faEyeSlash} />
										)}
									</Button>
								</InputRightElement>
							</InputGroup>
							<FormErrorMessage>{formik.errors.password}</FormErrorMessage>
						</FormControl>

						<Box mb='5'>
							<Button
								type='submit'
								colorScheme='blue'
								width={{ base: '100%' }}
								loadingText='Login'
								spinnerPlacement='end'
								isLoading={formik.isSubmitting}
							>
								Login
							</Button>
						</Box>
						<Box mb='5' textAlign='center'>
							<Link color='teal.500' as={NavLink} to='/forgot-password'>
								Forgot password?
							</Link>
						</Box>
						<Box
							display={{ base: 'block', md: 'none' }}
							mt='5'
							textAlign='center'
						>
							Not registered?{' '}
							<Link color='teal.500' as={NavLink} to='/register'>
								Sign up here
							</Link>
						</Box>
					</FormikProvider>
				</form>
			</Box>
		</>
	);
}
