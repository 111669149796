import React, { useState } from 'react';
import {
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	Button,
	InputGroup,
	InputRightElement,
	InputLeftAddon,
	FormHelperText,
	UnorderedList,
	ListItem,
	Text,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default function StepOne({ formik, nextFunction }) {
	const [showPassword, setshowPassword] = useState(false);
	return (
		<>
			<FormControl
				id='full_name'
				mb='5'
				isInvalid={formik.errors.full_name}
				isRequired
			>
				<FormLabel>Full Name</FormLabel>
				<Input
					type='text'
					autoFocus={true}
					{...formik.getFieldProps('full_name')}
				/>
				<FormErrorMessage>{formik.errors.full_name}</FormErrorMessage>
			</FormControl>
			<FormControl id='email' mb='5' isInvalid={formik.errors.email} isRequired>
				<FormLabel>Email address</FormLabel>
				<Input type='text' {...formik.getFieldProps('email')} />
				<FormErrorMessage>{formik.errors.email}</FormErrorMessage>
			</FormControl>
			<FormControl id='phone' mb='5' isInvalid={formik.errors.phone} isRequired>
				<FormLabel>Phone</FormLabel>
				<InputGroup>
					<InputLeftAddon
						pointerEvents='none'
						children='+91'
						color='gray.500'
					/>
					<Input type='text' {...formik.getFieldProps('phone')} />
				</InputGroup>
				<FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
			</FormControl>
			<FormControl
				id='password'
				mb='5'
				isInvalid={formik.errors.password}
				isRequired
			>
				<FormLabel>Password</FormLabel>
				<InputGroup>
					<Input
						type={showPassword ? 'text' : 'password'}
						{...formik.getFieldProps('password')}
					/>
					<InputRightElement>
						<Button
							type='button'
							onClick={() => setshowPassword(!showPassword)}
							size='sm'
						>
							{showPassword ? (
								<FontAwesomeIcon icon={faEye} />
							) : (
								<FontAwesomeIcon icon={faEyeSlash} />
							)}
						</Button>
					</InputRightElement>
				</InputGroup>
				<FormErrorMessage>{formik.errors.password}</FormErrorMessage>
				<FormHelperText>
					<Text>Your password must:</Text>
					<UnorderedList ml='6'>
						<ListItem>Be at least 8 characters long</ListItem>
						<ListItem>Have one lowercase character</ListItem>
						<ListItem>Have one uppercase character</ListItem>
						<ListItem>Have one special character(@$!%*?&)</ListItem>
						<ListItem>Have one digit</ListItem>
					</UnorderedList>
				</FormHelperText>
			</FormControl>
			<Button
				type='button'
				colorScheme='blue'
				width={{ base: '100%', md: 'auto' }}
				onClick={() => nextFunction()}
			>
				Next
			</Button>
		</>
	);
}
