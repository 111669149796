import React from 'react';
import Dashboard from '../pages/dashboard/Dashboard';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Flex, Box } from '@chakra-ui/react';
import Header from '../pages/layout/components/Header';
import Sidebar from '../pages/layout/components/Sidebar';
import NotFound from '../pages/layout/NotFound';
import AdmissionForm from '../pages/admissionform/AdmissionForm';
import { UserPage } from '../pages/user/UserPage';
import { useSelector } from 'react-redux';
import { AdminRoutes } from './AdminRoutes';

export default function AuthenticatedRoutes() {
	const { user } = useSelector((state) => state.auth);
	return (
		<Flex>
			<Sidebar />
			<Flex flexGrow='1' direction='column' overflowX='auto'>
				<Header />
				<Box p={{ base: '2', md: '10' }} flexGrow='1'>
					<Switch>
						<Redirect exact from='/login' to='/' />
						<Route exact path='/' component={Dashboard} />
						<Route path='/user' component={UserPage} />
						{user.role === 'student' && (
							<Route>
								<Switch>
									<Route path='/admission-form' component={AdmissionForm} />
									<Route component={NotFound} />
								</Switch>
							</Route>
						)}
						{user.role === 'admin' && (
							<Route>
								<AdminRoutes />
							</Route>
						)}
						<Route component={NotFound} />
					</Switch>
				</Box>
			</Flex>
		</Flex>
	);
}
