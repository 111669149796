import {
	Box,
	Heading,
	Link,
	Alert,
	AlertIcon,
	AlertDescription,
} from '@chakra-ui/react';
import { useFormik, FormikProvider } from 'formik';
import React, { useEffect } from 'react';
import { useState } from 'react';
import * as Yup from 'yup';
import { NavLink, useHistory } from 'react-router-dom';
import StepOne from './components/register/StepOne';
import StepTwo from './components/register/StepTwo';
import { getCitiesByState, getStates } from '../../utils/locationAPI';
import { register } from './redux/authAPI';
import { useDispatch } from 'react-redux';
import { setAuthMsg, setUser } from './redux/userRedux';

export default function Register() {
	const [currentStep, setcurrentStep] = useState(1);
	const [cities, setcities] = useState([]);
	const [states, setstates] = useState([]);
	const [cityLoading, setcityLoading] = useState(false);

	const dispatch = useDispatch();
	const history = useHistory();

	const initialValues = {
		full_name: '',
		email: '',
		phone: '',
		password: '',
		education: '',
		applying_for: '',
		city: '',
		state: '',
		reference_number: '',
	};

	const passwordRegex =
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

	const validationSchema = Yup.object().shape({
		full_name: Yup.string().required().label('Full name'),
		email: Yup.string().email('Invalid email').required().label('Email'),
		password: Yup.string()
			.test('regex', 'Password does not match all conditions', (value) =>
				passwordRegex.test(value)
			)
			.required()
			.label('Password'),
		phone: Yup.number()
			.test('length', 'Must be exactly 10 digits', (val) => {
				if (val) {
					return val.toString().length === 10;
				}
				return false;
			})
			.required(),
		education: Yup.string().required().label('Education'),
		applying_for: Yup.string()
			.oneOf(['pgdm', 'pgdme'])
			.required()
			.label('Applying for'),
		city: Yup.string().required().label('City'),
		state: Yup.string().required().label('State'),
		reference_number: Yup.string().label('Reference Number'),
	});

	const submitFunction = (values, setStatus, setErrors) => {
		return register(values)
			.then((res) => {
				dispatch(setUser(res.data.user));
				dispatch(
					setAuthMsg(
						'Registration successful, please confirm your email to proceed'
					)
				);
				history.push('/login');
			})
			.catch((err) => {
				if (err?.response?.data?.errors) {
					setErrors(err.response.data.errors);
				} else {
					setStatus(err?.response?.data?.message);
				}
			});
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: (values, { setStatus, setErrors }) => {
			return submitFunction(values, setStatus, setErrors);
		},
	});

	const nextFunction = async () => {
		const errors = await formik.validateForm();
		if (errors.full_name || errors.email || errors.password || errors.phone) {
			return;
		}
		formik.setErrors({});
		return setcurrentStep(2);
	};

	const handleStateChange = (state) => {
		formik.setFieldValue('state', state);
		if (!state) {
			return setcities([]);
		}
		setcityLoading(true);
		getCitiesByState(state)
			.then(async (res) => {
				setcities(res.data.cities);
				setcityLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const backFunction = () => {
		setcurrentStep(1);
	};

	useEffect(() => {
		const getStateList = () => {
			getStates()
				.then((res) => {
					setstates(res.data.states);
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getStateList();
	}, []);

	return (
		<>
			<Box
				position='absolute'
				top='20px'
				right='20px'
				display={{ base: 'none', md: 'block' }}
			>
				Already Registered?{' '}
				<Link color='teal.500' as={NavLink} to='/login'>
					Sign in here
				</Link>
			</Box>
			<Box width='90%' maxWidth='350px'>
				<form onSubmit={formik.handleSubmit}>
					<FormikProvider value={formik}>
						<Heading
							as='h4'
							size='xl'
							color='blue.700'
							textAlign='center'
							mb='5'
						>
							Register
						</Heading>
						{formik.status && (
							<Alert status='error' mb='5'>
								<AlertIcon />
								<AlertDescription>{formik.status}</AlertDescription>
							</Alert>
						)}
						{currentStep === 1 && (
							<StepOne formik={formik} nextFunction={nextFunction} />
						)}
						{currentStep === 2 && (
							<StepTwo
								backFunction={backFunction}
								formik={formik}
								cities={cities}
								states={states}
								handleStateChange={handleStateChange}
								cityLoading={cityLoading}
							/>
						)}
						<Box
							display={{ base: 'block', md: 'none' }}
							mt='5'
							textAlign='center'
						>
							Already Registered?{' '}
							<Link color='teal.500' as={NavLink} to='/login'>
								Sign in here
							</Link>
						</Box>
					</FormikProvider>
				</form>
				{/* <pre>{JSON.stringify(formik.values, null, 2)}</pre>
				<pre>{JSON.stringify(formik.errors, null, 2)}</pre> */}
			</Box>
		</>
	);
}
