import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	user: undefined,
	token: undefined,
	authMsg: undefined,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		removeUser: (state, action) => {
			state.user = undefined;
		},
		updateUser: (state, action) => {
			state.user = { ...state.user, ...action.payload };
		},
		setAccessToken: (state, action) => {
			state.accessToken = action.payload;
		},
		removeAccessToken: (state, action) => {
			state.accessToken = undefined;
		},
		setAuthMsg: (state, action) => {
			state.authMsg = action.payload;
		},
		removeAuthMsg: (state, action) => {
			state.authMsg = undefined;
		},
	},
});

const {
	setUser,
	removeUser,
	updateUser,
	setAccessToken,
	removeAccessToken,
	setAuthMsg,
	removeAuthMsg,
} = userSlice.actions;

const auth = userSlice.reducer;

export {
	auth,
	setUser,
	removeUser,
	updateUser,
	setAccessToken,
	removeAccessToken,
	setAuthMsg,
	removeAuthMsg,
};
