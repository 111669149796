import {
	Box,
	Heading,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import React from 'react';

export const AcademicTable = ({ ssc, hsc, grad }) => {
	if (!ssc && !hsc && !grad) {
		return (
			<Box>
				<Heading size='md' mb='3'>
					2. Academic Details
				</Heading>
				<Text>Student has not completed this part of the form yet</Text>
			</Box>
		);
	}
	return (
		<Box overflow='auto' mb='6'>
			<Heading size='md' mb='6'>
				2. Academic Details
			</Heading>

			<Table>
				<Thead>
					<Tr>
						<Th>Qualification</Th>
						<Th>Board</Th>
						<Th>Institute</Th>
						<Th>Subjects/specialization</Th>
						<Th>Year of passing</Th>
						<Th>Score</Th>
					</Tr>
				</Thead>
				<Tbody>
					<Tr>
						<Td>SSC</Td>
						{ssc && (
							<>
								<Td>{ssc.board}</Td>
								<Td>{ssc.school}</Td>
								<Td>-</Td>
								<Td>{ssc.year_of_passing}</Td>
								<Td>{ssc.score}</Td>
							</>
						)}
					</Tr>
					<Tr>
						<Td>HSC</Td>
						{hsc ? (
							<>
								<Td>{hsc.board}</Td>
								<Td>{hsc.school}</Td>
								<Td>{hsc.main_subjects}</Td>
								<Td>{hsc.year_of_passing}</Td>
								<Td>{hsc.score}</Td>
							</>
						) : (
							<Td colSpan='5'>Student has not cleared HSC</Td>
						)}
					</Tr>
					<Tr>
						<Td>Graduation {grad && `(${grad.degree})`}</Td>
						{grad ? (
							<>
								<Td>{grad.university}</Td>
								<Td>{grad.institute}</Td>
								<Td>{grad.specialization}</Td>
								<Td>{grad.year_of_passing}</Td>
								<Td>-</Td>
							</>
						) : (
							<Td colSpan='5'>Student is not a graduate</Td>
						)}
					</Tr>
				</Tbody>
			</Table>
		</Box>
	);
};
