import { Box, Heading, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AbsoluteLoader from '../../utils/components/AbsoluteLoader';
import { getUser } from './redux/adminAPI';
import { AddressPersonalTable } from './components/AddressPersonalTable';
import { AcademicTable } from './components/AcademicTable';
import { WorkExperienceTable } from './components/WorkExperienceTable';
import { FileUploadsTable } from './components/FileUploadsTable';

export const AdminSingleUser = () => {
	const { student_id } = useParams();
	const [details, setdetails] = useState();
	const [loading, setloading] = useState(true);

	useEffect(() => {
		getUser(student_id)
			.then((res) => {
				setdetails(res.data.student);
				setloading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [student_id]);
	return (
		<Box>
			{loading ? (
				<AbsoluteLoader />
			) : (
				<>
					{details && (
						<Box mb='6'>
							<Heading size='xl' mb='2'>
								Details for student: {details.full_name}
							</Heading>
							<Text>
								Applying for:{' '}
								<Text as='span' textTransform='uppercase' fontWeight='medium'>
									{details.applying_for}
								</Text>
							</Text>
						</Box>
					)}
					<AddressPersonalTable
						pers={details.personal_details}
						addr={details.address_details}
					/>
					<FileUploadsTable
						ups={details.upload_details}
						email={details.email}
					/>
					<AcademicTable
						ssc={details.ssc_details}
						hsc={details.hsc_details}
						grad={details.graduation_details}
					/>
					<WorkExperienceTable we={details.work_experience_details} />
				</>
			)}
		</Box>
	);
};
