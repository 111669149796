import { Box, Center, Circle, Progress, Text } from '@chakra-ui/react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function Dashboard() {
	const { user } = useSelector((state) => state.auth);
	const [completePercent, setcompletePercent] = useState();

	useEffect(() => {
		let temp =
			user.applying_for === 'pgdm'
				? Math.round((user.current_form / 5) * 100 * 100) / 100
				: Math.round((user.current_form / 6) * 100 * 100) / 100;
		setcompletePercent(temp);
	}, [user.applying_for, user.current_form]);
	return (
		<Box mx={{ base: 'auto', md: '0' }}>
			<Text fontSize='xl' mb='4'>
				You are applying for -{` `}
				<Text as='span' fontWeight='medium' textTransform='uppercase'>
					{user.applying_for}
				</Text>
			</Text>
			<Center
				border='2px solid'
				borderColor='gray.200'
				p='6'
				maxWidth='600px'
				rounded='xl'
				flexDirection='column'
			>
				<Circle
					bg={completePercent === 100 ? 'green.200' : 'green.200'}
					color={completePercent === 100 ? 'green.700' : 'green.700'}
					fontWeight='semibold'
					fontSize='3xl'
					size='90px'
					mb='6'
				>
					{completePercent}%
				</Circle>
				<Progress
					value={completePercent}
					w='full'
					rounded='md'
					colorScheme='green'
					mb='6'
				/>
				<Text fontSize='lg' textAlign='center'>
					Your admission form is {completePercent}% complete
				</Text>
			</Center>
		</Box>
	);
}
