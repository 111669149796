import { useFormik, FormikProvider, FieldArray } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import {
	Box,
	Heading,
	SimpleGrid,
	GridItem,
	Text,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	Button,
	Flex,
	Alert,
	AlertIcon,
	AlertDescription,
} from '@chakra-ui/react';
import {
	ArrowBackIcon,
	ArrowForwardIcon,
	CloseIcon,
	PlusSquareIcon,
} from '@chakra-ui/icons';
import * as Yup from 'yup';
import { steps } from '../../../utils/constants';
import { updateUser } from '../../auth/redux/userRedux';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
	addWorkExperienceDetails,
	getWorkExperienceDetails,
	putWorkExperienceDetails,
} from '../redux/AdmissionAPI';
import _ from 'lodash';

export default function WorkExperienceDetails() {
	const dispatch = useDispatch();
	const history = useHistory();

	const { user } = useSelector((state) => state.auth);

	const baseWork = {
		from_date: '',
		to_date: '',
		company_name: '',
		designation: '',
		responsibilities: '',
	};

	const [initialValues, setinitialValues] = useState({
		work_experience: [baseWork],
	});

	const validationSchema = Yup.object().shape({
		work_experience: Yup.array()
			.of(
				Yup.object().shape({
					from_date: Yup.string().required().label('From date'),
					to_date: Yup.string().required().label('To date'),
					company_name: Yup.string().required().label('Company name').max(255),
					designation: Yup.string().required().label('Designation').max(255),
					responsibilities: Yup.string()
						.required()
						.label('Responsibilities')
						.max(255),
				})
			)
			.min(1, 'Minimum 1 work experience is required for PGDME program'),
	});

	const submitFunction = (values, setStatus, setErrors) => {
		if (_.isEqual(initialValues, values)) {
			return history.push(
				`/admission-form/${user.applying_for}/address-details`
			);
		}
		if (user.current_form > 3) {
			return putWorkExperienceDetails(values)
				.then((res) => {
					history.push(`/admission-form/${user.applying_for}/address-details`);
				})
				.catch((err) => {
					if (err?.response?.data?.errors) {
						return setErrors(err.response.data.errors);
					}
					return setStatus(err.response.data.message);
				});
		}
		return addWorkExperienceDetails(values)
			.then((res) => {
				dispatch(updateUser({ current_form: res.data.current_form }));
				history.push(
					`/admission-form/${user.applying_for}/${
						steps[user.applying_for][res.data.current_form]
					}`
				);
			})
			.catch((err) => {
				if (err?.response?.data?.errors) {
					return setErrors(err.response.data.errors);
				}
				return setStatus(err.response.data.message);
			});
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: (values, { setStatus, setErrors }) => {
			return submitFunction(values, setStatus, setErrors);
		},
	});

	useEffect(() => {
		const getInitialData = () => {
			getWorkExperienceDetails()
				.then((res) => {
					setinitialValues({ work_experience: res.data.workExperience });
				})
				.catch((err) => {
					console.log(err);
				});
		};
		if (user.current_form < 3) {
			history.push(
				`/admission-form/${user.applying_for}/${
					steps[user.applying_for][user.current_form]
				}`
			);
		}
		if (user.current_form > 3) {
			getInitialData();
		}
	}, [history, user]);

	return (
		<Box px={{ base: '2', md: '10' }}>
			<form onSubmit={formik.handleSubmit}>
				<Heading as='h4' size='xl' mb='10'>
					Step 4: Work Experience Details
				</Heading>
				<SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} spacing={10} mb='10'>
					<GridItem colSpan={{ base: 1, md: 2, xl: 3 }}>
						<Text
							fontSize='sm'
							color='gray.500'
							textDecoration='underline'
							mb='2'
						>
							Work Experience Details
						</Text>
						{formik.status && (
							<Alert status='error' mb='5'>
								<AlertIcon />
								<AlertDescription>{formik.status}</AlertDescription>
							</Alert>
						)}
					</GridItem>
					<FormikProvider value={formik}>
						<FieldArray
							name='work_experience'
							render={(arrayHelpers) =>
								formik.values.work_experience &&
								formik.values.work_experience.length > 0 ? (
									formik.values.work_experience.map(
										(work_experience, arrayIndex) => {
											return (
												<Fragment key={arrayIndex}>
													<GridItem colSpan={{ base: 1, md: 2, xl: 3 }}>
														<Flex
															justifyContent='space-between'
															alignItems='center'
														>
															<Text
																fontSize='sm'
																color='gray.500'
																textDecoration='underline'
															>
																Job #{arrayIndex + 1}
															</Text>
															<Button
																colorScheme='red'
																size='sm'
																onClick={() => arrayHelpers.remove(arrayIndex)}
															>
																<CloseIcon />
															</Button>
														</Flex>
													</GridItem>
													<FormControl
														mb='5'
														isInvalid={
															formik.errors.work_experience &&
															formik.errors.work_experience.from_date
														}
														isRequired
													>
														<FormLabel>From date</FormLabel>
														<Input
															type='date'
															{...formik.getFieldProps(
																`work_experience[${arrayIndex}].from_date`
															)}
														/>
														<FormErrorMessage>
															{formik.errors.work_experience &&
																formik.errors.work_experience[arrayIndex]
																	.from_date}
														</FormErrorMessage>
													</FormControl>
													<FormControl
														mb='5'
														isInvalid={
															formik.errors.work_experience &&
															formik.errors.work_experience.to_date
														}
														isRequired
													>
														<FormLabel>To date</FormLabel>
														<Input
															type='date'
															{...formik.getFieldProps(
																`work_experience[${arrayIndex}].to_date`
															)}
														/>
														<FormErrorMessage>
															{formik.errors.work_experience &&
																formik.errors.work_experience[arrayIndex]
																	.to_date}
														</FormErrorMessage>
													</FormControl>
													<FormControl
														mb='5'
														isInvalid={
															formik.errors.work_experience &&
															formik.errors.work_experience.company_name
														}
														isRequired
													>
														<FormLabel>Company name</FormLabel>
														<Input
															type='text'
															{...formik.getFieldProps(
																`work_experience[${arrayIndex}].company_name`
															)}
														/>
														<FormErrorMessage>
															{formik.errors.work_experience &&
																formik.errors.work_experience[arrayIndex]
																	.company_name}
														</FormErrorMessage>
													</FormControl>
													<FormControl
														mb='5'
														isInvalid={
															formik.errors.work_experience &&
															formik.errors.work_experience.designation
														}
														isRequired
													>
														<FormLabel>Designation</FormLabel>
														<Input
															type='text'
															{...formik.getFieldProps(
																`work_experience[${arrayIndex}].designation`
															)}
														/>
														<FormErrorMessage>
															{formik.errors.work_experience &&
																formik.errors.work_experience[arrayIndex]
																	.designation}
														</FormErrorMessage>
													</FormControl>
													<FormControl
														mb='5'
														isInvalid={
															formik.errors.work_experience &&
															formik.errors.work_experience.responsibilities
														}
														isRequired
													>
														<FormLabel>Responsibilities</FormLabel>
														<Input
															type='text'
															{...formik.getFieldProps(
																`work_experience[${arrayIndex}].responsibilities`
															)}
														/>
														<FormErrorMessage>
															{formik.errors.work_experience &&
																formik.errors.work_experience[arrayIndex]
																	.responsibilities}
														</FormErrorMessage>
													</FormControl>
													{arrayIndex ===
														formik.values.work_experience.length - 1 && (
														<GridItem colSpan={{ base: 1, md: 2, xl: 3 }}>
															<Button
																type='button'
																colorScheme='blue'
																rightIcon={<PlusSquareIcon />}
																isLoading={formik.isSubmitting}
																spinnerPlacement='end'
																onClick={() => arrayHelpers.push(baseWork)}
															>
																Add
															</Button>
														</GridItem>
													)}
												</Fragment>
											);
										}
									)
								) : (
									<Button
										type='button'
										colorScheme='blue'
										rightIcon={<PlusSquareIcon />}
										isLoading={formik.isSubmitting}
										spinnerPlacement='end'
										onClick={() => arrayHelpers.push(baseWork)}
									>
										Add
									</Button>
								)
							}
						/>
					</FormikProvider>
				</SimpleGrid>
				<Flex justifyContent='space-between' alignItems='center'>
					<Button
						as={Link}
						colorScheme='gray'
						leftIcon={<ArrowBackIcon />}
						to={`/admission-form/${user.applying_for}/academic-details`}
					>
						Previous Step
					</Button>
					<Button
						type='submit'
						colorScheme='green'
						rightIcon={<ArrowForwardIcon />}
						isLoading={formik.isSubmitting}
						spinnerPlacement='end'
					>
						Save and proceed
					</Button>
				</Flex>
				{/* <pre>{JSON.stringify(formik.values, null, 2)}</pre>
				<br />
				<pre>{JSON.stringify(formik.errors, null, 2)}</pre> */}
			</form>
		</Box>
	);
}
