import { Center, CircularProgress, Heading } from '@chakra-ui/react';
import React from 'react';

export default function FullScreenLoader() {
	return (
		<Center
			position='fixed'
			top='0'
			left='0'
			width='100%'
			height='100vh'
			flexDirection='column'
			backgroundColor='white'
			zIndex='500'
		>
			<CircularProgress mb='3' isIndeterminate size='80px' />
			<Heading as='h4' size='xl'>
				Loading
			</Heading>
		</Center>
	);
}
