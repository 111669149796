import { Box } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import AcademicDetails from './components/AcademicDetails';
import AddressDetails from './components/AddressDetails';
import PersonalDetails from './components/PersonalDetails';
import Confirmation from './components/Confirmation';
import Uploads from './components/Uploads';
import WorkExperienceDetails from './components/WorkExperienceDetails';
import WizardHeader from './components/WizardHeader';
import { steps } from '../../utils/constants';
import NotFound from '../layout/NotFound';
import { FormSubmitted } from './components/FormSubmitted';

export default function AdmissionForm() {
	const { user } = useSelector((state) => state.auth);
	const location = useLocation();
	return (
		<>
			<Switch>
				{user && !user.is_form_submitted ? (
					<>
						<Box mb='10'>
							{user && (
								<WizardHeader
									applying_for={user.applying_for}
									current_step={user.current_form}
									active_step={steps[user.applying_for].indexOf(
										location.pathname.split('/').pop()
									)}
								/>
							)}
						</Box>
						<Route>
							<Switch>
								<Route
									exact
									path={`/admission-form/${user.applying_for}/personal-details`}
									component={PersonalDetails}
								/>
								<Route
									exact
									path={`/admission-form/${user.applying_for}/address-details`}
									component={AddressDetails}
								/>
								<Route
									exact
									path={`/admission-form/${user.applying_for}/academic-details`}
									component={AcademicDetails}
								/>
								{user.applying_for === 'pgdme' && (
									<Route
										exact
										path={`/admission-form/${user.applying_for}/work-experience-details`}
										component={WorkExperienceDetails}
									/>
								)}
								<Route
									exact
									path={`/admission-form/${user.applying_for}/uploads`}
									component={Uploads}
								/>
								<Route
									exact
									path={`/admission-form/${user.applying_for}/confirmation`}
									component={Confirmation}
								/>
								<Route component={NotFound} />
							</Switch>
						</Route>
					</>
				) : (
					<Route>
						<Switch>
							<Route
								exact
								path={`/admission-form/form-submitted`}
								component={FormSubmitted}
							/>
							<Redirect to='/admission-form/form-submitted' />
						</Switch>
					</Route>
				)}
			</Switch>
		</>
	);
}
