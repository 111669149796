import axios from 'axios';

// Personal Details
export const addPersonalDetails = (data) => {
	return axios.post('/admission/personal-details', data);
};

export const getPersonalDetails = () => {
	return axios.get('/admission/personal-details');
};

export const putPersonalDetails = (data) => {
	return axios.put(`/admission/personal-details`, data);
};

// Address Details
export const addAddressDetails = (data) => {
	return axios.post('/admission/address-details', data);
};

export const getAddressDetails = () => {
	return axios.get('/admission/address-details');
};

export const putAddressDetails = () => {
	return axios.put('/admission/address-details');
};

// Academic details
export const addAcademicDetails = (data) => {
	return axios.post('/admission/academic-details', data);
};

export const getAcademicDetails = () => {
	return axios.get('/admission/academic-details');
};

export const putAcademicDetails = (data) => {
	return axios.put('/admission/academic-details', data);
};

// work experience details
export const addWorkExperienceDetails = (data) => {
	return axios.post('/admission/work-experience-details', data);
};

export const getWorkExperienceDetails = () => {
	return axios.get('/admission/work-experience-details');
};

export const putWorkExperienceDetails = (data) => {
	return axios.put('/admission/work-experience-details', data);
};

// upload files
export const addFiles = (data) => {
	return axios.post('/admission/upload-details', data);
};

export const patchFiles = (data) => {
	return axios.patch('/admission/upload-details', data);
};

export const getFile = (params) => {
	return axios.get(`/admission/upload-details`, {
		params,
		responseType: 'blob',
	});
};

export const getZip = (params) => {
	return axios.get(`/admission/upload-details/zip`, {
		params,
		responseType: 'blob',
	});
};

// confirm admission
export const confirmAdmission = (data) => {
	return axios.post('/admission/apply', data);
};
