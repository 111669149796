import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import UnauthenticatedRoutes from './UnauthenticatedRoutes';

export default function Routes() {
	const { user } = useSelector((state) => state.auth);
	return (
		<BrowserRouter>
			<Switch>
				{user ? (
					<Route>
						<AuthenticatedRoutes />
					</Route>
				) : (
					<Route>
						<UnauthenticatedRoutes />
					</Route>
				)}
			</Switch>
		</BrowserRouter>
	);
}
