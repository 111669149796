import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import FullScreenLoader from '../../utils/components/FullScreenLoader';
import { verifyEmail } from './redux/authAPI';
import { setAuthMsg } from './redux/userRedux';

export default function VerifyEmail() {
	const { emailToken } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		const sendMail = async () => {
			if (!emailToken) {
				history.push('/login');
			}
			if (emailToken) {
				await verifyEmail({ emailToken })
					.then((res) => {
						dispatch(
							setAuthMsg('Email verified successfully, please login to proceed')
						);
					})
					.catch((err) => {
						dispatch(
							setAuthMsg(
								'There was an error verifying your email, if you think you should not be seeing this message, send a query to techteam@kmmias.org'
							)
						);
					});
			}
			history.push('/login');
		};
		sendMail();
	}, [dispatch, history, emailToken]);

	return <FullScreenLoader />;
}
