export const setupAxios = (
	axios,
	store,
	setAccessToken,
	removeAccessToken,
	removeUser
) => {
	axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;
	axios.defaults.headers.post['Content-Type'] = 'application/json';
	axios.interceptors.request.use(
		(config) => {
			const token = store.getState().auth.accessToken;
			if (token) {
				config.headers['Authorization'] = `Bearer ${token}`;
			}
			return config;
		},
		(error) => {
			Promise.reject(error);
		}
	);

	axios.interceptors.response.use(
		(response) => {
			return response;
		},
		function (error) {
			const originalRequest = error.config;
			if (
				error.response.status === 401 &&
				originalRequest.url === '/auth/refresh-token'
			) {
				store.dispatch(removeAccessToken);
				store.dispatch(removeUser);
				return Promise.reject(error);
			}

			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				return axios
					.get('/auth/refresh-token', {
						withCredentials: true,
					})
					.then((res) => {
						if (res.status === 201) {
							store.dispatch(setAccessToken(res.data.accessToken));
							originalRequest.headers['Authorization'] =
								'Bearer ' + res.data.accessToken;
							return axios(originalRequest);
						}
					});
			}
			return Promise.reject(error);
		}
	);
};
