import { Center, Box, Text } from '@chakra-ui/react';
import React from 'react';

export default function NotFound() {
	return (
		<Center mt='20'>
			<Box textAlign='center' p='10' rounded='md'>
				<Text as='h1' fontSize='9xl'>
					404
				</Text>
				<Text fontSize='2xl'>
					Sorry we could not find what you were looking for
				</Text>
			</Box>
		</Center>
	);
}
