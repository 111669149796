import React from 'react';
import {
	Drawer,
	DrawerOverlay,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	Flex,
	Box,
	Text,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThLarge, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { steps } from '../../../utils/constants';

export default function MobileDrawer({ isOpen, onClose, user }) {
	return (
		<Drawer isOpen={isOpen} placement='left' onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent backgroundColor='gray.700' color='white'>
				<DrawerCloseButton />

				<DrawerBody mt='10'>
					{user && (
						<Flex direction='column' textAlign='left' p='2'>
							<Box
								as={NavLink}
								to='/'
								py='2'
								px='10'
								mb='1'
								_hover={{ backgroundColor: 'gray.600' }}
								exact
								rounded='md'
							>
								<FontAwesomeIcon icon={faThLarge} />
								<Text as='span' ml='2'>
									Dashboard
								</Text>
							</Box>
							<Box
								as={NavLink}
								to={`/admission-form/${user.applying_for}/${
									steps[user.applying_for][user.current_form]
								}`}
								mb='1'
								py='2'
								px='10'
								_hover={{ backgroundColor: 'gray.700' }}
								rounded='md'
							>
								<FontAwesomeIcon icon={faClipboardList} />
								<Text as='span' ml='2'>
									Admission Form
								</Text>
							</Box>
						</Flex>
					)}
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
}
