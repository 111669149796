import { faCheck } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { wizardSteps } from '../../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Flex,
	Circle,
	Box,
	Text,
	Center,
	LinkOverlay,
	LinkBox,
} from '@chakra-ui/react';
import { Link as domLink } from 'react-router-dom';

export default function WizardHeader({
	applying_for,
	current_step,
	active_step,
}) {
	const lastIndex = wizardSteps[applying_for].length - 1;
	const showStep = (index) => {
		if (current_step === 0) {
			if (index < 3) {
				return true;
			}
			return false;
		}
		if (current_step === lastIndex) {
			if (index > lastIndex - 3) {
				return true;
			}
			return false;
		}
		if (
			index === current_step + 1 ||
			index === current_step - 1 ||
			index === current_step
		) {
			return true;
		}
		return false;
	};
	const showGap = (index) => {
		if (current_step === 0) {
			if (index < 2) {
				return true;
			}
			return false;
		}
		if (current_step === lastIndex) {
			if (index > lastIndex - 2) {
				return true;
			}
			return false;
		}
		if (index === current_step - 1 || index === current_step) {
			return true;
		}
		return false;
	};
	return (
		<>
			<Flex spacing='0' justifyContent='space-between'>
				{wizardSteps[applying_for].map((element, index) => {
					return (
						<Flex
							justifyContent='flex-start'
							flexGrow={{
								base: showGap(index) ? '1' : '0',
								lg: index === lastIndex ? '0' : '1',
							}}
							key={index}
							display={{ base: showStep(index) ? 'flex' : 'none', lg: 'flex' }}
						>
							<LinkBox
								display='flex'
								flexDirection='column'
								alignItems='center'
								px='5'
							>
								<Circle
									bg={index >= current_step ? 'gray.200' : 'green.500'}
									size='40px'
									color={index >= current_step ? 'gray.700' : 'white'}
									fontSize='20px'
									zIndex='1'
									border='1px solid'
									borderColor={index <= current_step ? 'green.500' : 'white'}
									mr='1'
									ring={index === active_step && '4'}
									ringColor={index === active_step && 'green.200'}
								>
									{index < current_step ? (
										<FontAwesomeIcon icon={faCheck} />
									) : (
										<FontAwesomeIcon icon={element.icon} />
									)}
								</Circle>
								{current_step >= index ? (
									<LinkOverlay
										as={domLink}
										to={element.link}
										textAlign='center'
										fontWeight={index === active_step && 'bold'}
									>
										{element.text}
									</LinkOverlay>
								) : (
									<Text
										textAlign='center'
										fontWeight={index === active_step && 'bold'}
									>
										{element.text}
									</Text>
								)}
								<Text fontSize='xs' color='gray.400'>
									{`step ${index + 1}/${lastIndex + 1}`}
								</Text>
							</LinkBox>
							<Center flexGrow='1'>
								<Box
									width='100%'
									height='2px'
									backgroundColor={
										index < current_step ? 'green.500' : 'gray.200'
									}
								></Box>
							</Center>
						</Flex>
					);
				})}
			</Flex>
		</>
	);
}
