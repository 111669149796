import React, { useState } from 'react';
import {
	Box,
	SimpleGrid,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	RadioGroup,
	Stack,
	Radio,
	Select,
	Heading,
	Text,
	GridItem,
	InputGroup,
	InputLeftAddon,
	Button,
	Alert,
	AlertIcon,
	AlertDescription,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { ArrowForwardIcon, PhoneIcon } from '@chakra-ui/icons';
import * as Yup from 'yup';
import {
	addPersonalDetails,
	getPersonalDetails,
	putPersonalDetails,
} from '../redux/AdmissionAPI';
import { updateUser } from '../../auth/redux/userRedux';
import { steps } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { differenceInYears } from 'date-fns';
import { useEffect } from 'react';
import _ from 'lodash';

export default function PersonalDetails() {
	const { user } = useSelector((state) => state.auth);
	const [showNationalityInput, setshowNationalityInput] = useState(false);
	const [nationalityRadioValue, setnationalityRadioValue] = useState('');
	const bloodGroups = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'];
	const dispatch = useDispatch();
	const history = useHistory();

	const [initialValues, setinitialValues] = useState({
		first_name: '',
		middle_name: '',
		last_name: '',
		date_of_birth: '',
		gender: '',
		blood_group: '',
		nationality: '',
		email: '',
		phone: '',
		father_name: '',
		father_email: '',
		father_phone: '',
		mother_name: '',
		mother_email: '',
		mother_phone: '',
	});

	const validationSchema = Yup.object().shape({
		first_name: Yup.string().required().max(50).label('First name'),
		middle_name: Yup.string().max(50).label('Middle name'),
		last_name: Yup.string().required().max(50).label('Last name'),
		date_of_birth: Yup.string()
			.required()
			.label('Date of birth')
			.test('age', 'Cannot be less than 18 years of age', (value) => {
				const diff = differenceInYears(new Date(), new Date(value));
				return diff > 18;
			}),
		gender: Yup.string()
			.oneOf(['male', 'female', 'other'])
			.required()
			.label('Gender'),
		blood_group: Yup.string().oneOf(bloodGroups).required(),
		nationality: Yup.string().required().max(50).label('Nationality'),
		email: Yup.string().email().required().max(255).label('Email'),
		phone: Yup.number()
			.test('ofLength', 'Should be exactly 10 digits long', (value) => {
				if (value) {
					return value.toString().length === 10;
				}
			})
			.required()
			.label('Mobile number')
			.typeError('Must be digits only'),
		father_name: Yup.string().required().max(150).label('Name'),
		father_email: Yup.string().email().required().max(255).label('Email'),
		father_phone: Yup.number()
			.test('ofLength', 'Should be exactly 10 digits long', (value) => {
				if (value) {
					return value.toString().length === 10;
				}
			})
			.required()
			.label('Mobile number')
			.typeError('Must be digits only'),
		mother_name: Yup.string().required().max(150).label('Name'),
		mother_email: Yup.string().email().required().max(255).label('Email'),
		mother_phone: Yup.number()
			.test('ofLength', 'Should be exactly 10 digits long', (value) => {
				if (value) {
					return value.toString().length === 10;
				}
			})
			.required()
			.label('Mobile number')
			.typeError('Must be digits only'),
	});

	const submitFunction = (values, setStatus, setErrors) => {
		if (_.isEqual(initialValues, values)) {
			return history.push(
				`/admission-form/${user.applying_for}/address-details`
			);
		}
		if (user.current_form > 0) {
			return putPersonalDetails(values)
				.then((res) => {
					history.push(`/admission-form/${user.applying_for}/address-details`);
				})
				.catch((err) => {
					if (err?.response?.data?.errors) {
						return setErrors(err.response.data.errors);
					}
					return setStatus(err.response.data.message);
				});
		}
		return addPersonalDetails(values)
			.then((res) => {
				dispatch(updateUser({ current_form: res.data.current_form }));
				history.push(
					`/admission-form/${user.applying_for}/${
						steps[user.applying_for][res.data.current_form]
					}`
				);
			})
			.catch((err) => {
				if (err?.response?.data?.errors) {
					return setErrors(err.response.data.errors);
				}
				return setStatus(err.response.data.message);
			});
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: (values, { setStatus, setErrors }) => {
			return submitFunction(values, setStatus, setErrors);
		},
	});

	const handleNationalityChange = (value) => {
		if (value === 'indian') {
			setshowNationalityInput(false);
			setnationalityRadioValue('indian');
			return formik.setFieldValue('nationality', 'indian');
		}
		if (value === 'other') {
			setshowNationalityInput(true);
			setnationalityRadioValue('other');
			return formik.setFieldValue('nationality', '');
		}
		return formik.setFieldValue('nationality', value);
	};

	const handleGenderChange = (value) => {
		formik.setFieldValue('gender', value);
	};

	useEffect(() => {
		if (user.current_form > 0) {
			getPersonalDetails()
				.then((res) => {
					setinitialValues(res.data.personalDetails);
					const { nationality } = res.data.personalDetails;
					if (nationality === 'indian') {
						setnationalityRadioValue(res.data.personalDetails.nationality);
					} else {
						setnationalityRadioValue('other');
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [user]);

	return (
		<Box px={{ base: '2', md: '10' }} position='relative'>
			<form onSubmit={formik.handleSubmit}>
				<Heading as='h4' size='xl' mb='10'>
					Step 1: Personal Details
				</Heading>
				<SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} spacing={10} mb='10'>
					<GridItem colSpan={{ base: 1, md: 2, xl: 3 }}>
						<Text
							fontSize='sm'
							color='gray.500'
							textDecoration='underline'
							mb='2'
						>
							Personal Information
						</Text>
						{formik.status && (
							<Alert status='error' mb='5'>
								<AlertIcon />
								<AlertDescription>{formik.status}</AlertDescription>
							</Alert>
						)}
					</GridItem>
					<FormControl
						id='first_name'
						mb='5'
						isInvalid={formik.errors.first_name}
						isRequired
					>
						<FormLabel>First Name</FormLabel>
						<Input
							autoFocus
							type='text'
							{...formik.getFieldProps('first_name')}
						/>
						<FormErrorMessage>{formik.errors.first_name}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='middle_name'
						mb='5'
						isInvalid={formik.errors.middle_name}
					>
						<FormLabel>Middle Name</FormLabel>
						<Input type='text' {...formik.getFieldProps('middle_name')} />
						<FormErrorMessage>{formik.errors.middle_name}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='last_name'
						mb='5'
						isInvalid={formik.errors.last_name}
						isRequired
					>
						<FormLabel>Last Name</FormLabel>
						<Input type='text' {...formik.getFieldProps('last_name')} />
						<FormErrorMessage>{formik.errors.last_name}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='date_of_birth'
						mb='5'
						isInvalid={formik.errors.date_of_birth}
						isRequired
					>
						<FormLabel>Date of Birth</FormLabel>
						<Input type='date' {...formik.getFieldProps('date_of_birth')} />
						<FormErrorMessage>{formik.errors.date_of_birth}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='gender'
						mb='5'
						isInvalid={formik.errors.gender}
						isRequired
					>
						<FormLabel>Gender</FormLabel>
						<RadioGroup
							{...formik.getFieldProps('gender')}
							value={formik.values.gender}
							onChange={handleGenderChange}
						>
							<Stack direction='row'>
								<Radio value='male'>Male</Radio>
								<Radio value='female'>Female</Radio>
								<Radio value='other'>Other</Radio>
							</Stack>
						</RadioGroup>
						<FormErrorMessage>{formik.errors.gender}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='blood_group'
						mb='5'
						isInvalid={formik.errors.blood_group}
						isRequired
					>
						<FormLabel>Blood Group</FormLabel>
						<Select
							placeholder='Select option'
							{...formik.getFieldProps('blood_group')}
						>
							{bloodGroups.map((val, index) => {
								return (
									<option key={index} value={val}>
										{val}
									</option>
								);
							})}
						</Select>
						<FormErrorMessage>{formik.errors.blood_group}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='nationality'
						mb='5'
						isInvalid={formik.errors.nationality}
						isRequired
					>
						<FormLabel>Nationality</FormLabel>
						<RadioGroup
							{...formik.getFieldProps('nationality')}
							value={nationalityRadioValue}
							onChange={handleNationalityChange}
						>
							<Stack direction='row'>
								<Radio value='indian'>Indian</Radio>
								<Radio value='other'>Other</Radio>
							</Stack>
						</RadioGroup>
						{showNationalityInput && (
							<FormControl mt='3' isInvalid={formik.errors.nationality}>
								<Input
									placeholder='Please specify'
									value={formik.values.nationality}
									onChange={(e) => handleNationalityChange(e.target.value)}
								/>
							</FormControl>
						)}
						<FormErrorMessage>{formik.errors.nationality}</FormErrorMessage>
					</FormControl>
					<GridItem colSpan={{ base: 1, md: 2, xl: 3 }}>
						<Text fontSize='sm' color='gray.500' textDecoration='underline'>
							Contact Information
						</Text>
					</GridItem>
					<FormControl
						id='email'
						mb='5'
						isInvalid={formik.errors.email}
						isRequired
					>
						<FormLabel>Email address</FormLabel>
						<Input type='text' {...formik.getFieldProps('email')} />
						<FormErrorMessage>{formik.errors.email}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='phone'
						mb='5'
						isInvalid={formik.errors.phone}
						isRequired
					>
						<FormLabel>Phone</FormLabel>
						<InputGroup>
							<InputLeftAddon
								pointerEvents='none'
								children='+91'
								color='gray.500'
							/>
							<Input type='number' {...formik.getFieldProps('phone')} />
						</InputGroup>
						<FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
					</FormControl>

					<GridItem colSpan={{ base: 1, md: 2, xl: 3 }}>
						<Text fontSize='sm' color='gray.500' textDecoration='underline'>
							Guardian Information
						</Text>
					</GridItem>
					<FormControl
						id='father_name'
						mb='5'
						isInvalid={formik.errors.father_name}
					>
						<FormLabel>Father's Name</FormLabel>
						<Input type='text' {...formik.getFieldProps('father_name')} />
						<FormErrorMessage>{formik.errors.father_name}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='father_email'
						mb='5'
						isInvalid={formik.errors.father_email}
					>
						<FormLabel>Father's Email</FormLabel>
						<Input type='text' {...formik.getFieldProps('father_email')} />
						<FormErrorMessage>{formik.errors.father_email}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='father_phone'
						mb='5'
						isInvalid={formik.errors.father_phone}
					>
						<FormLabel>Father's Contact</FormLabel>
						<InputGroup>
							<InputLeftAddon
								pointerEvents='none'
								children={<PhoneIcon color='gray.500' />}
							/>
							<Input type='number' {...formik.getFieldProps('father_phone')} />
						</InputGroup>
						<FormErrorMessage>{formik.errors.father_phone}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='mother_name'
						mb='5'
						isInvalid={formik.errors.mother_name}
					>
						<FormLabel>Mother's Name</FormLabel>
						<Input type='text' {...formik.getFieldProps('mother_name')} />
						<FormErrorMessage>{formik.errors.mother_name}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='mother_email'
						mb='5'
						isInvalid={formik.errors.mother_email}
					>
						<FormLabel>Mother's Email</FormLabel>
						<Input type='text' {...formik.getFieldProps('mother_email')} />
						<FormErrorMessage>{formik.errors.mother_email}</FormErrorMessage>
					</FormControl>
					<FormControl
						id='mother_phone'
						mb='5'
						isInvalid={formik.errors.mother_phone}
					>
						<FormLabel>Mother's Contact</FormLabel>
						<InputGroup>
							<InputLeftAddon
								pointerEvents='none'
								children={<PhoneIcon color='gray.500' />}
							/>
							<Input type='number' {...formik.getFieldProps('mother_phone')} />
						</InputGroup>
						<FormErrorMessage>{formik.errors.mother_phone}</FormErrorMessage>
					</FormControl>
				</SimpleGrid>
				<Box textAlign='right'>
					<Button
						type='submit'
						colorScheme='green'
						rightIcon={<ArrowForwardIcon />}
						isLoading={formik.isSubmitting}
						spinnerPlacement='end'
					>
						Save and proceed
					</Button>
				</Box>
				{/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
			</form>
		</Box>
	);
}
