import {
	Box,
	Heading,
	Link,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	Button,
	Alert,
	AlertIcon,
} from '@chakra-ui/react';
import { useFormik, FormikProvider } from 'formik';
import React from 'react';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import { forgotPassword } from './redux/authAPI';

export default function ForgotPassword() {
	const initialValues = {
		email: '',
	};

	const validationSchema = Yup.object().shape({
		email: Yup.string().email().required(),
	});

	const submitFunction = (values, setStatus) => {
		return forgotPassword(values)
			.then((res) => {
				setStatus(
					'A password reset link has been sent  to your email, use this link to reset your password. The link will expire in 24 hours'
				);
			})
			.catch((err) => {
				setStatus(err.response.data.message);
			});
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values, { setStatus }) => {
			return submitFunction(values, setStatus);
		},
	});

	return (
		<>
			<Box
				position='absolute'
				top='20px'
				right='20px'
				display={{ base: 'none', md: 'block' }}
			>
				Back to login,{` `}
				<Link color='teal.500' as={NavLink} to='/login'>
					Click here
				</Link>
			</Box>
			<Box width='90%' maxWidth='350px'>
				<form onSubmit={formik.handleSubmit}>
					<FormikProvider value={formik}>
						<Heading
							as='h4'
							size='xl'
							color='blue.700'
							textAlign='center'
							mb='5'
						>
							Forgot Password
						</Heading>
						{formik.status && (
							<Alert status='info' mb='5'>
								<AlertIcon />
								{formik.status}
							</Alert>
						)}
						<FormControl
							id='email'
							mb='5'
							isInvalid={formik.errors.email && formik.touched.email}
							isRequired
						>
							<FormLabel>Email address</FormLabel>
							<Input type='text' {...formik.getFieldProps('email')} />
							<FormErrorMessage>{formik.errors.email}</FormErrorMessage>
						</FormControl>

						<Button
							type='submit'
							colorScheme='blue'
							width={{ base: '100%', md: 'auto' }}
							loadingText='Submit'
							spinnerPlacement='end'
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
						<Box
							display={{ base: 'block', md: 'none' }}
							mt='5'
							textAlign='center'
						>
							Back to login,
							<Link color='teal.500' as={NavLink} to='/login'>
								Click here
							</Link>
						</Box>
					</FormikProvider>
				</form>
			</Box>
		</>
	);
}
